import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const applyRule = (rule: string, journeyId: string) =>
  api.put(`${envConfig.RULES_ENGINE_ROUTE}/v3/rule/apply?journeyId=${journeyId}&rule=${rule}`, {})
    .then((response) => response.data)
    .catch(() => {
      return false
    });

export const setRule = (set: string, journeyId: string) =>
  api.put(`${envConfig.RULES_ENGINE_ROUTE}/api/v1/set/apply?journeyId=${journeyId}&name=${set}&globalSet=true`, {})
    .then(response => response.data);