<template>
  <div class="backoffice">
    <Button class="backoffice__action" v-for="button in homeButtonsDisplay"
            :label="translate(`BUTTONS.${button.label}`)"
            @click="redirect(button.action)"
            :action-icon="!homeButtonsAvailable.includes(button.label)  ? 'padlock-blue.png' : ''"
            :disabled="!homeButtonsAvailable.includes(button.label)"
            :title="`${!homeButtonsAvailable.includes(button.label)  ?  'COMING SOON' : ''}`"
    />
  </div>
</template>

<script lang="ts">
import {translate} from "@/i18n";
import {defineComponent} from "vue";
import Button from "@/components/button/Button.vue";
import {redirect} from "@/utils/utils";
import {mapGetters} from "vuex";

export default defineComponent({
  components: {Button},
  methods: {
    redirect,
    translate
  },
  data: () => ({
    homeButtons: [
      {label: 'MY_COMPANY', action: '/back-office/my-company'},
      {label: 'MY_USERS', action: '/back-office/users'},
      {label: 'CONSUMPTION_MONITORING', action: '/back-office/my-usage'},
      {label: 'RULES_ENGINE', action: '/back-office/rules-engine/sets'}
    ],
    homeButtonsAvailable: ['MY_COMPANY', 'CONSUMPTION_MONITORING', 'MY_USERS', 'RULES_ENGINE']
  }),
  computed: {
    ...mapGetters(['user']),
    homeButtonsDisplay() {
      return this.user.roles?.includes('COMPANY_ADMIN') ? this.homeButtons : this.homeButtons.filter(ele => ele.label !== 'RULES_ENGINE');
    }
  },
})
</script>