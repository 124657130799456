import api from '@/utils/api';
import envConfig from "@/utils/envConfig";

export const getRules = (journeyId: string | null = null) => {
    let url = `${envConfig.RULES_ENGINE_ROUTE}/v3/rules`;
    if (journeyId) url += `?journeyId=${journeyId}`;
    return api.get(url).then(response => response.data.rules);
}
   

export const getRule = (name: string, globalRule: boolean, journeyId: string | null = null) => {
    let url = `${envConfig.RULES_ENGINE_ROUTE}/v3/rules?name=${name}&globalRule=${globalRule}`;
    if (journeyId) url += `&journeyId=${journeyId}`;
    return api.get(url)
    .then(response => response?.data?.rules?.length > 0 ? response.data.rules[0]:null);
}

export const changeParams = (params: any, journeyId: string | null = null) => {
    let url = `${envConfig.RULES_ENGINE_ROUTE}/v3/params`;
    if (journeyId) url += `?journeyId=${journeyId}`;
    return api.put(url, params)
    .then(response => response.data);
}

export const getSets = (journeyId: string | null = null) => {
    let url = `${envConfig.RULES_ENGINE_ROUTE}/v3/sets`
    if (journeyId) url += `?journeyId=${journeyId}`;
    return api.get(url)
    .then(response => response.data.sets);
}

export default {getRules, getRule, changeParams};
