import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import moment from "moment/moment";
import { useToast } from "vue-toast-notification";

import { translate } from "@/i18n";
import errorHandler from "@/middleware/responseHandler";
import router from "@/router";
import cookiesUtils from "@/utils/cookiesUtils";
import { checkUserPayload } from "@/utils/filterUserPayload";
import { getTokenFromUrlParams } from "@/utils/getTokenFromUrlParams";

const toast = useToast();

// axios.defaults.withCredentials = true;

const apiPortal = axios.create({
  timeout: 120000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  // withCredentials: true,
});

apiPortal.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    const controller = new AbortController();
    if (config.method && ["post", "put"].includes(config.method)) {
      try {
        checkUserPayload(config);
      } catch (e) {
        controller.abort();
        toast.error(translate("MALICIOUS_PAYLOAD"), { duration: 5 * 1000 });
      }
    }
    let accessToken =
      getTokenFromUrlParams() || cookiesUtils.getCookie("access_token");
    if (!accessToken) return config;
    if (config.url?.includes("/authenticate/2fa/setup/validate")) return config;
    let configWithHeaders;
    if (config.url?.includes("?")) config.url += "&";
    else config.url += "?";
    config.url += `access_token=${accessToken}`;
    configWithHeaders = {
      ...config,
      signal: controller.signal,
    };
    return configWithHeaders;
  }
);

apiPortal.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status >= 200 && response.status <= 299) {
      cookiesUtils.setCookie("connexion_date", moment().format("LLL"));
    }
    if (response.status === 404) {
      toast.warning(translate("ERRORS.NO_DATA_FOUND"), { duration: 5 * 1000 });
    }
    if (response.status >= 400 && response.status <= 499) {
      toast.error(translate("ERRORS.ERROR_OCCURRED"), { duration: 5 * 1000 });
    }
    return response;
  },
  async (error): Promise<never> => {
    if (!error.response) return Promise.reject(error);
    if (error.response.status !== 401) return Promise.reject(error);
    if (window.location.pathname !== "/login")
      await router.push({ name: "LogoutPage", replace: false });
    return Promise.reject(error);
  }
);

apiPortal.interceptors.response.use(
  (response: AxiosResponse) => {
    const pathname = window.location.pathname;
    if (pathname === "/analysis-b2c") {
      errorHandler.handleB2CResponseFailed(response);
    }
    if (pathname === "/analysis-b2b") {
      errorHandler.handleB2BResponseFailed(response);
    }
    if (pathname === "/analysis-b2b" || pathname === "/analysis-b2c") {
      errorHandler.handleB2BAndB2CResponseFailed(response);
    }
    return response;
  },
  (error: AxiosError) => {
    errorHandler.handleServerError(error);
    return Promise.reject(error);
  }
);

export default apiPortal;
