import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {CustomerProfileV3} from "@/types/CustomerProfile";
import _ from 'lodash';
import {isEmpty, isObject} from "lodash";
import {createdAfter} from "@/utils/dateTime";
import {AxiosResponse} from "axios";
import {ComputedRef} from "vue";
import store from "@/store";

interface ResponseElement {
  type: string;
  value: any;
  id: string;
  status: string;
  status_reason: string;
  created_at: string
}

interface HistoricalParams {
  apiCall: 'me' | 'users' | 'companies/me' | 'team',
  countByPage: number,
  pageId: number,
  marketType: String[],
  fields: Array<Object>,
  userIds?: Array<number>,
  companyIds?: Array<number>
}

interface HistoricalDocumentParams {
  apiCall: 'me' | 'users' | 'companies/me' | 'team',
  countByPage: number,
  pageId: number,
  userIds?: Array<number>,
  companyIds?: Array<number>
}

export interface createJourneyIdResponse {
  businessId: string,
  client: string,
  companyId: number,
  createdAt: string,
  externalId: string,
  id: string,
  origin: string,
  statuses: string[],
  tags: string[],
  updatedAt: string,
  userdId: number
}

export const createJourneyIdByTask = (
  origin?: string,
  clientId?: string,
  externalId?: string,
  tags?: Array<string>
): Promise<createJourneyIdResponse> =>
  api
    .post(
      `${envConfig.BACKEND_ROUTE}/tasks/v3/journeys`,
      JSON.stringify({
        origin,
        clientId,
        externalId,
        tags,
      })
    )
    .then((response) => response.data);

export const sendApplicantInformation = (
  data: CustomerProfileV3,
  journeyId: string,
  iban?: string
) => {
  return api.post(
    `${envConfig.BACKEND_ROUTE}/tasks/v3/journeys/${journeyId}/contexts`,
    {
      type: "PERSONAL",
      subType: "PERSON",
      version: "V1",
      label: "PERSON",
      data: {
        domain: "PERSONAL",
        dtoName: "PERSON",
        version: "V1",
        strict: false,

        externalId: "MEELO-PORTAL",
        firstname: data.identity.firstName,
        lastname: data.identity.lastName,
        mobilePhone: data.identity.mobileNumber,
        email: data.identity.email,
        birthDate: data.identity.birthDate,
        addresses: [
          {
            domain: "ADDRESS",
            dtoName: "ADDRESS",
            version: "V1",
            strict: false,

            addressType: "MAIN",
            street1: data.address.street,
            zipcode: data.address.zipCode,
            city: data.address.city,
            country: data.address.country,
          },
        ],
        iban: iban,
      },
    }
  );
};

function mapResponse(
  element: ResponseElement,
  index: number,
  responseKey: any[]
): any {
  if (element.status === "FAILED") {
    if (element && element.value && element.value.data) {
      element.value.data.status = element.status;
      element.value.data.statusReason = element.status_reason;
    } else {
      const status = {
        data: {
          status: element.status,
          statusReason: element.status_reason,
        },
      };
      element.value = {...element.value, ...status};
    }
  }

  if (
    _.isEqual(index, _.size(responseKey) - 1) &&
    _.isEqual(element.type, "OPENBANKING") &&
    _.isObject(element.value) &&
    !_.isNull(element.value)
  ) {
    return {...element.value, taskId: element.id};
  }

  if (element.type === 'OCR' || element.type === 'FILE_UPLOAD_REQUEST' || element.type === 'AUTHENTICITY' || element.type === 'ANALYSIS' || element.type === 'CHECK' || element.type === 'LIVE_CHECK') {
    return element.value = {...element.value, status: element.status, taskId: element.id, creationDate: element.created_at};
  }

  return element.value;
}

export const getData = (journeyId: string, fields: Array<Object>) =>
  api
    .post(
      `${envConfig.BACKEND_ROUTE}/tasks/v3/journeys/${journeyId}/contexts/data/fields`,
      {
        fields: fields,
      }
    )
    .then((response) => {
      let response_clone = response;
      if (Array.isArray(Object.keys(response.data.data))) {
        Object.keys(response.data.data).forEach((key) => {
          response_clone.data.data[key] = response.data.data[key].map(
            (element: ResponseElement, index: number) => {
              if(key ==='web_upload_link_result' || key ==='web_upload_link_causes') {
                return element
              }
              return mapResponse(element, index, response.data.data[key]);
            }
          );
        });
      }
      //@ts-ignore
      response_clone.data.data['businessId'] = response.data.businessId; 
      response_clone.data.data['clientId'] = response.data.clientId;
      store.dispatch('taskModule/updateTaskData', response_clone.data);
      return response_clone;
    });

export const getHistorical = async ({
                                      apiCall,
                                      countByPage,
                                      pageId,
                                      marketType,
                                      fields,
                                      userIds,
                                      companyIds
                                    }: HistoricalParams) => {
  let whereCondition;
  if (marketType.includes("portal-b2b")) {
    whereCondition = {
      operator: "NOT_EQUALS",
      field: "company_legal_unit",
      value: null,
    };
  } else if (marketType.includes("document-analysis")) {
    whereCondition = null;
  } else if (marketType.includes("open-banking")) {
    whereCondition = {
      operator: "NOT_EQUALS",
      field: "open_banking_account",
      value: null,
    };
  } else {
    whereCondition = {
      "operator": "OR",
      "or": [
        {
          "operator": "NOT_EQUALS",
          "field": "web_score_b2c",
          "value": null
        },
        {
          "operator": "NOT_EQUALS",
          "field": "check_iban_details",
          "value": null
        },
        {
          "operator": "NOT_EQUALS",
          "field": "enrichment", 
          "value": null
        }
      ]
    };
  }
  const {data} = await api.post(
    `${envConfig.BACKEND_ROUTE}/tasks/v5/journeys/search/${apiCall}`,
    {
      pageId: pageId,
      countByPage: countByPage,
      where: whereCondition,
      fields: fields,
      createdAfter: createdAfter,
      clientIds: marketType,
      sorts: [
        {
          field: "JOURNEY_createdAt",
          direction: "DESC",
        },
      ],
      userIds: userIds,
      companyIds: companyIds
    }
  );
  let data_clone = data;
  if (Array.isArray(data.journeys)) {
    data_clone.data = data.journeys.map((journey: any) => {
      Object.keys(journey.data).forEach((key) => {
        journey.data[key] = journey.data[key].map((e: any) => {
          if (e.type === "OPENBANKING") {
            return typeof e.value == "number" ? {value: e.value, status: e.status} : {...e.value, status: e.status};
          }
          return e.value;
        });
      });
      return journey;
    });
    return data_clone;
  }
};

export const getHistoricalDocument = async ({
                                              apiCall,
                                              countByPage,
                                              pageId,
                                              userIds,
                                              companyIds
                                            }: HistoricalDocumentParams) => {
  const {data} = await api.post(
    `${envConfig.BACKEND_ROUTE}/storage/v2/documents/history/${apiCall}`,
    {
      pageId: pageId,
      countByPage: countByPage,
      userIds: userIds,
      companyIds: companyIds
    }
  )

  return data;
};

export const updateJourneyIdStatus = async (journeyId: string, status: string, causes: string, id: string) => {
  api.put(`${envConfig.BACKEND_ROUTE}/tasks/v3/journeys/status`, {
    journeyId: journeyId,
    status: status,
    causes: causes,
    id: id
  }).then((response) => response.data)
};

export const getAllJourneyIdStatus = async (journeyId: string) => {
  return api.get(`${envConfig.BACKEND_ROUTE}/tasks/v3/journeys/status/${journeyId}/status`).then((response) => response.data);
};

export const addJourneyIdStatus = async (journeyId: string, status: string, causes: string) => {
  const {data} = await api.post(`${envConfig.BACKEND_ROUTE}/tasks/v3/journeys/status`, {
    journeyId: journeyId,
    status: status,
    causes: causes,
  });

  return data;
};

export const getLatestJourneyIdStatus = async (journeyId: string) => {
  return api.get(`${envConfig.BACKEND_ROUTE}/tasks/v3/journeys/status/${journeyId}/status/latest`).then((response) => response.data);
};

export const getJourneyIdDetails = (journeyId: string) => {
  return api.get(`${envConfig.BACKEND_ROUTE}/tasks/v3/journeys/${journeyId}`);
}