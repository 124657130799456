export default {
  "ACCOUNT_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "GENERAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "PHONE_NUMBER_NOT_SPECIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non renseigné"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrage de l'authentification à double facteurs"])},
      "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requis par l'entreprise"])},
      "CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifié"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier mes informations"])},
      "MODE": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode d'authentification"])},
        "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
        "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])}
      }
    },
    "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes informations"])}
  },
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiez-vous"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])},
    "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les identifiants ne correspondent à aucun compte."])},
    "ATTEMPTS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tentatives consécutives erronées bloqueront votre compte."])},
    "LOCKED_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été bloqué."])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai oublié mon mot de passe"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à deux facteurs"])},
      "ALERT_MESSAGE_CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération écrasera votre ancien moyen d'authentification à deux facteurs. Etes-vous sûr de vouloir continuer ?"])},
      "ALERT_MESSAGE_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération activera l’authentification à deux facteurs."])},
      "ALERT_MESSAGE_DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette opération désactivera l’authentification à deux facteurs."])},
      "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver l'authentification à deux facteurs"])},
      "DISABLE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désactiver l’authentification à deux facteurs. Néanmoins, nous vous recommandons de laisser cette fonctionnalité activée afin de renforcer la sécurité de votre compte."])},
      "COMPANY_2FA_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez nous indiquer le moyen d’authentification que vous souhaitez utiliser."])},
      "EMAIL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un code de vérification vous sera envoyé sur l’adresse mail"])},
      "SMS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un code de vérification vous sera envoyé par SMS. Veuillez renseigner votre numéro de téléphone :"])},
      "TITLE_MODAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode d'authentification à double facteurs"])},
      "MESSAGE_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez recu un"])},
      "FINALISATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de finaliser l'authentification à double facteurs, merci de saisir le code qui vous a été envoyé"])}
    }
  },
  "RESET_PASSWORD_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation du mot de passe"])},
    "INIT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialiser le mot de passe"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre email"])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "CONFIRM_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation du nouveau mot de passe"])},
    "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un email de réinitialisation"])},
    "SUCCESS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un email de réinitialisation de mot de passe vous a été envoyé."])},
    "EMAIL_NOT_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email n'a pas pu être envoyé."])},
    "INVALID_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial."])},
    "PASSWORD_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne correspondent pas."])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a bien été réinitialisé."])},
    "PASSWORD_RESET_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la réinitialisation de votre mot de passe."])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En continuant, vous acceptez la"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Meelo"])}
  },
  "IDENTITY": {
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "PLACE_OF_BIRTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone mobile"])},
    "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "USAGE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'usage"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
  },
  "ADDRESS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "TITLE_POSTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse postale"])},
    "NUMBER_AND_STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° et nom de rue"])},
    "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])}
  },
  "COMPANY": {
    "NO_REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dirigeants et représentants connus"])},
    "NO_BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun actionnaires et bénéficiaires connus"])},
    "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidation"])},
    "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiation"])},
    "SIREN_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
    "ORIGIN": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgique"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])}
    },
    "REGISTRATION_NUMBER": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d’identification entreprise ou SIREN"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de registre national ou Numéro de TVA"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'Identification Fiscale"])}
    },
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
    "SIRET_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
    "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
    "MAIN_ACTIVITY_DECLARED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité principale déclarée"])},
    "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code NAF ou APE"])},
    "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de l'entreprise"])},
    "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
    "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison sociale"])},
    "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forme juridique"])},
    "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital social"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité de l'entreprise"])},
    "BUSINESS_OWNERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des bénéficiaires effectifs"])},
    "BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaires effecitfs connus"])},
    "DIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaire direct"])},
    "INDIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaire indirect via personne morale"])},
    "IN_POSITION_SINCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En position depuis le"])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise en sommeil"])},
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procédures en cours"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procédures collectives"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-réputation"])},
    "GLOBAL_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note globale"])},
    "ADVICE_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'avis"])},
    "WORDING_CLOUD_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récurrence des mots dans les avis"])},
    "ADJECTIVES_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis positifs / Avis négatifs dans les avis"])},
    "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web"])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'établissements"])},
    "ESTABLISHMENTS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des établissements"])},
    "REPRESENTATIVES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des représentants"])},
    "REPRESENTATIVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représentants"])},
    "REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirigeants et représentants connus"])},
    "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actionnaires et bénéficiaires connus"])},
    "RECOMMENDED_OUTSTANDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours recommandé"])},
    "HEADQUARTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siège social"])},
    "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "TOTAL_SHARE_HOLDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détention totale d'actions"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays d'origine de l'entreprise"])},
    "TYPE_PERSON": {
      "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne physique"])},
      "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne morale"])}
    },
    "FUNCTIONS": {
      "PRESIDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président"])},
      "CHAIRMAN_OF_THE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du conseil d'administration"])},
      "CHAIRMAN_AND_CHIEF_EXECUTIVE_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président directeur général"])},
      "MANAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérant"])},
      "NON_ASSOCIATE_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérant non associé"])},
      "CO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-gérant"])},
      "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général"])},
      "GENERAL_MANAGER_NOT_A_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général non administrateur"])},
      "SOLE_MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général unique"])},
      "CHIEF_OPERATING_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général délégué"])},
      "VICE_CHAIRMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice-président"])},
      "DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
      "DEPUTY_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur délégué"])},
      "CHAIRMAN_OF_THE_EXECUTIVE_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du directoire"])},
      "CHAIRMAN_OF_THE_SUPERVISORY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du conseil de surveillance"])},
      "MANAGING_PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associé-gérant"])},
      "CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôleur de gestion"])},
      "STATUTORY_AUDITOR_TITULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissaire aux comptes - titulaire"])},
      "STATUTORY_AUDITOR_DEPUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissaire aux comptes - suppléant"])},
      "STATUTORY_AUDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissaire aux comptes"])},
      "LIQUIDATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquitadeur"])},
      "PROVISIONAL_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur provisoire"])},
      "REPRESENTATIVE_IN_FRANCE_OF_A_FOREIGN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représentant en France d'une société étrangère"])},
      "MEMBER_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre du comité d'administration"])},
      "CHAIRMAN_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président du comité d'administration"])}
    },
    "TYPE": {
      "SOCIETY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société"])},
      "COLLECTIVE_INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placement collectif"])},
      "GIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe d'intérêt économique"])}
    },
    "OF_SHARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de parts"])},
    "OF_VOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de votes"])},
    "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations juridiques"])},
    "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectif"])},
    "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de TVA"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de clôture"])},
    "ACTIVITY_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État d'activité"])},
    "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
    "UNVERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non domicilié"])},
    "RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandation"])},
    "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine d'activité"])},
    "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissements"])},
    "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siège"])},
    "IN_OPERATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En activité"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermé"])},
    "FINANCES": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances entreprise"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée financière disponible"])},
      "RATIOS": {
        "grossMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marge brute (€)"])},
        "netMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marge nette (€)"])},
        "valueAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur ajoutée (€)"])},
        "ebitda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excédent brut d’exploitation (€)"])},
        "ebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat d’exploitation (€)"])},
        "profiteBeforeTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat courant avant impôt (€)"])},
        "extraordinaryResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat extraordinaire (€)"])},
        "netIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat net (€)"])},
        "shortTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettes à court terme (€)"])},
        "longMidTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettes à long et moyen terme (€)"])},
        "turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiffre d'affaire (€)"])},
        "balanceSheetTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total du bilan (€)"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitaux propres (€)"])},
        "workingCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds de roulement (€)"])},
        "workingCapitalRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin de fonds de roulement (€)"])},
        "financialLiquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tresorie nette (€)"])},
        "intangibleAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilisations incorporelles (€)"])},
        "tangibleFixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilisations corporelles (€)"])},
        "participatingInterestsAndRelatedDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilisations financières (€)"])},
        "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock (€)"])},
        "othersDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres créances (€)"])},
        "tradeDebtorsAndRelatedAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créance client (€)"])},
        "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trésorie (€)"])},
        "chargesCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charge CA (€)"])},
        "appropriationsToDepreciationAndProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amortissements (€)"])},
        "totalExtraordinaryIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits exceptionnels (€)"])},
        "totalExtraordinaryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges exceptionnelles (€)"])},
        "corporateTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôt sur les sociétés (€)"])},
        "operatingLiabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettes d'exploitations (€)"])},
        "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital (€)"])},
        "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve (€)"])},
        "profitOrLossForTheFinancialYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat d'exercice (€)"])},
        "investmentGrantsTaxRegulatedProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre (€)"])},
        "loansAndDebtsPayableToCreditInstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bancaire moyen long terme (€)"])},
        "loansAndSundryFinancialDebtsPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emprunts dettes financières diverses (€)"])},
        "changeInStocksIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres produits (€)"])},
        "purchasesOfGoodsForResale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat de marchandises (€)"])},
        "changeInStocksCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variation de stock (€)"])},
        "otherExternalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres achats et charges externes (€)"])},
        "taxesLeviesAndSimilarPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôts, taxes et versements (€)"])},
        "personnelCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaires et traitements (€)"])},
        "otherCurrentOperatingCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres charges (€)"])},
        "financialIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits financiers (€)"])},
        "financialCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges financières (€)"])},
        "fpTb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds propres sur total bilan (%)"])},
        "supplierDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delais fournisseur (jours)"])},
        "customerDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delais client (jours)"])},
        "caf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité d'autofinancement (€)"])},
        "rnCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat net sur chiffre d'affaires (%)"])},
        "etp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equivalent temps plein"])},
        "dividends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividendes versés aux actionnaires (€)"])},
        "soldeFinancier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde financier (€)"])},
        "salesGrowthRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de croissance du CA (%)"])},
        "grossMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de marge brute (%)"])},
        "ebitdaMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de marge d'EBE (%)"])},
        "operatingMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de marge opérationnelle (%)"])}
      },
      "BLOCKS": {
        "intermediateOperationTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soldes intermédiaires de gestion"])},
        "debts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettes"])},
        "balanceSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance du bilan"])},
        "fixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif immobilise"])},
        "currentAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif circulant"])},
        "profitAndLossAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte de résultat"])},
        "balancePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan passif"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitaux propres"])},
        "operatingIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits d'exploitation"])},
        "operatingCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût d'exploitation"])},
        "operatingResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat d'exploitation"])},
        "ratios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratios financiers"])},
        "increase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Croissance"])}
      }
    },
    "LEGAL_DOCUMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents juridiques"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document juridique disponible"])},
      "NO_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour consulter le document, merci de le télécharger."])}
    },
    "BODACC_ANNOUNCEMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évolution entreprise"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "NATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature"])},
      "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail"])}
    },
    "ANNUAL_ACCOUNTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes annuels"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun compte annuel disponible"])},
      "COMPANY_FINANCIAL_STATEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes sociaux"])}
    },
    "CHECKS": {
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d’un bilan déposé à la même date"])},
      "LESS_THAN_X_YEARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise de moins de 3 ans"])},
      "MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de gérance inférieur à 3 ans"])},
      "SHARE_CAPITAL_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de capital inférieur à 3 ans"])},
      "HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert de siège inférieur à 3 ans"])},
      "ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement d’activité inférieur à 3 ans"])},
      "MULTI_MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 2 changements de gérance au moins au cours des 10 dernières années"])},
      "HAS_CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessation d'activité"])},
      "HAS_ALL_ESTABLISHMENTS_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les établissements sont fermés"])},
      "HAS_ACTIVITY_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de deux changements d'activité au moins au cours des 10 dernières années"])},
      "HAS_HEADQUARTER_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de deux transferts de siège au moins au cours des 10 dernières années"])},
      "LOCATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation à risque"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise Domiciliée"])},
      "WEBSITE_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création du domaine du site web"])},
      "EMAIL_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création du domaine de l'email"])},
      "EMAIL_DOMAIN_EQUALS_WEBSITE_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance entre le domaine de l'email et du site web"])},
      "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiation"])},
      "COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procédures en cours"])},
      "OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre entreprise en procédure collective"])},
      "OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre entreprise radiée"])},
      "ACTIVITIES_CODE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur d'activité risqué"])},
      "HEADQUARTER_DISTANCE_TOO_HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance importante entre l'adresse du siège et le domicile du gérant"])},
      "LEGAL_SITUATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de contentieux"])},
      "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d'inactivité supérieure à 3ans"])},
      "MULTI_HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 2 transferts de siège au moins au cours des 10 dernières années"])},
      "MULTI_ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 2 changements d'activité au moins au cours des 10 dernières années"])},
      "CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessation d'activité"])},
      "ALL_ESTABLISHMENTS_ARE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les établissements sont fermés"])},
      "CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactabilité des données"])},
      "HEADQUARTER_ADDRESS_IN_WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraude détectée à cette adresse"])},
      "MULTI_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d’une immatriculation depuis la création"])},
      "MORE_THAN_ONE_ESTABLISHMENT_DOMICILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un établissement est domicilié"])}
    },
    "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliation"])},
    "BRANCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement secondaire"])},
    "CHECK_EMPLOYER": {
      "NO_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'information n'a pas pu être vérifiée"])},
      "FALSE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entreprise n'est pas radiée"])},
      "TRUE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entreprise est radiée"])},
      "COLLECTIVE_PROCEEDING": {
        "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune procédure collective en cours détectée"])},
        "MORE_THAN_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une ou plusieurs procédures collectives en cours détectées"])},
        "RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une ou plusieurs procédures collectives en cours détectées, dont au moins une récente (< 3 ans)"])}
      }
    }
  },
  "ERRORS": {
    "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups ! Il semblerait qu'il y ait un problème. Nos équipes travaillent déjà sur sa résolution. Merci de ré-essayer plus un peu plus tard."])},
    "NOT_FOUND_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Numéro d'identification saisi n'existe pas."])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune information récupérée"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre outil de traitement de liasse fiscale ne prend pas les documents antérieurs à 2018."])},
    "TAX_REPORT_TOO_OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale trop ancienne"])},
    "DOCUMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue durant la lecture du document"])},
    "COMPANY_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de d'identification saisi n’est rattaché à aucune entreprise en activité."])},
    "ENRICHMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune information n'a été trouvé."])},
    "ERROR_OCCURRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, veuillez ressayer ultérieurement"])},
    "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée, merci de commencer une nouvelle analyse"])},
    "CALL_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appel terminé, attention certaines données sont inaccessibles. Pour un dossier complet merci de commencer une nouvelle analyse"])},
    "SIREN_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse terminée, aucune donnée trouvée avec le Numéro d'identification renseigné"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée récupérée"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée disponible"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons rencontré une erreur avec le numéro de téléphone saisi. Veuillez vérifier l'information entrée"])}
  },
  "BUTTONS": {
    "VALIDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "VALIDATE_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider les informations"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
    "VERIFY_IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de document"])},
    "START_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer Open Banking"])},
    "JOURNEY_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complétez le parcours Open Banking dans le navigateur"])},
    "FINISH_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer l'analyse"])},
    "NEW_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer une nouvelle analyse"])},
    "EXPORT_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le résumé"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "DETAILS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'analyse"])},
    "VERIFY_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier l'adresse"])},
    "SEND_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un SMS"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un e-mail"])},
    "BACK_HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'accueil"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML/CFT"])},
    "VALIDATE_AND_SEND_SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider et envoyer un SMS"])},
    "VALIDATE_AND_SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider et envoyer un e-mail"])},
    "IFRAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe"])},
    "RUN_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer l'analyse"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse IBAN"])},
    "FRAUD_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores fraude"])},
    "SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
    "SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné"])},
    "CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir"])},
    "DEACTIVATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "ENGLISH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version anglaise"])},
    "FRENCH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version française"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer"])},
    "UPLOAD_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger ici"])},
    "MY_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon entreprise"])},
    "MY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes utilisateurs"])},
    "CONSUMPTION_MONITORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surveillance de la consommation"])},
    "EXTRACT_MEMORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraire la mémoire"])},
    "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moteur de règles"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "LINK_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien par SMS"])},
    "NEW_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle demande"])},
    "RESTART_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommencer la demande"])},
    "LINK_BROWSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien par navigateur"])},
    "LINK_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien par mail"])},
    "CREATE_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un utilisateur"])},
    "UNBLOCK_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débloquer un utilisateur"])},
    "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "CONFIRM_AND_CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider et continuer"])},
    "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
    "MODIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation du mot de passe"])},
    "ACTIVATE_DEACTIVATE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer / Désactiver le compte"])},
    "RESETTING_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation de la clé d’API"])},
    "DELETE_THE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le compte"])},
    "RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "CONSULT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter l’analyse Finovox"])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller sur linkedin"])},
    "DEPLOY_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout déployer"])},
    "REDUCE_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout réduire"])},
    "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score identité"])},
    "SCORE_COMPANY_FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score fraude entreprise"])},
    "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "SELECT_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
    "MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon équipe"])},
    "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "COMPLETE_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer l'upload"])},
    "LIVE_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de doutes"])}
  },
  "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandeur"])},
  "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
  "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactable"])},
  "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié"])},
  "MAIL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes liés au mail"])},
  "PHONE_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes liés au téléphone"])},
  "APPLICANT_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats du demandeur"])},
  "CONTACT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées"])},
  "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations client"])},
  "VERIFICATION_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de document"])},
  "VERIFICATION_TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification d'avis d'imposition"])},
  "CONFIDENCE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Fraude Identité"])},
  "SMB_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Fraude Identité Pro"])},
  "CONFIDENCE_INDEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Fraude Identité"])},
  "DETAILED_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du score"])},
  "CONFIDENCE_EXPLICATIONS": {
    "UNDER_TWENTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données fournies non liées à la personne"])},
    "BETWEEN_TWENTY_AND_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données douteuses, vérification d’identité recommandée"])},
    "ABOVE_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données fournies existantes et liées à la personne"])}
  },
  "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des documents"])},
  "ANALYSIS_RESULT_RETRIEVAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération des résultats d'analyse en cours ..."])},
  "UPLOAD_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoie de document terminé, vous pouvez fermer cette fenêtre."])},
  "LINK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien avec l'entreprise"])},
  "IDENTITY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence de l'identité"])},
  "COMPANY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence avec l'entreprise"])},
  "OTHER_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre personne"])},
  "SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation"])},
  "NOT_FOUND": {
    "NO_BUSINESS_OWNERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun bénéficiaires effectif connu."])},
    "NO_REPRESENTATIVES_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun représentant connu."])},
    "NO_LEADERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dirigeant récupéré..."])}
  },
  "OPEN_BANKING": {
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données ont bien été transmises. Vous pouvez fermer cette page pour voir le résultat de l'analyse."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "RESULT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de l’OpenBanking"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse détaillée de l'OpenBanking"])},
    "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "INCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrées"])},
    "OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorties"])},
    "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titulaire"])},
    "ACCOUNT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du compte"])},
    "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire mensuel moyen"])},
    "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyer mensuel moyen"])},
    "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde actuel"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de demander à votre client de se munir de son smartphone et ses coordonnées bancaires."])},
    "CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "FINANCIAL_ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d'analyse financière"])},
    "ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période analysée"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])},
    "CATEGORIES": {
      "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant maximal de transactions"])},
      "MONTH_MAX_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de la transaction maximale"])},
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel total"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Salaires"])},
      "MONTH_SALARIES_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date moyenne mensuelle - Salaires"])},
      "MONTH_INTERESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Intérêts"])},
      "MONTH_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Virements"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Loyers"])},
      "MONTH_WITHDRAWALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Retraits distributeurs"])},
      "MONTH_INSURANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Assurance"])},
      "MONTH_UTILITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Services publiques"])},
      "MONTH_GROCERIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Achats alimentaires"])},
      "MONTH_FOOD_CONSUMPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Consommation alimentaire"])},
      "MONTH_SHOPPINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Achats"])},
      "MONTH_HEALTH_WELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Santé/bien-être"])},
      "MONTH_TRANSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Transports"])},
      "MONTH_TELECOMMUNICATION_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Abonnements liés aux services de télécommunication"])},
      "MONTH_AUDIO_VISUAL_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant moyen mensuel - Abonnements liés aux services de l'audio-visuel"])},
      "MONTH_INCOMES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des revenus"])},
      "MONTH_INCOMES_SALARIES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des salaires"])},
      "MONTH_INCOMES_ALLOWANCES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des allocations"])},
      "MONTH_INCOMES_PENSIONS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des pensions"])},
      "MONTH_INCOMES_AMOUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle la plus haute"])},
      "MONTH_INCOMES_AMOUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle la plus basse"])},
      "MONTH_OUTCOMES_RENTS_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des loyers"])},
      "MONTH_OUTCOMES_CREDITS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des crédits"])},
      "MONTH_OUTCOMES_CREDITS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date des écheances des crédits"])},
      "MONTH_OUTCOMES_PENSIONS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'une pension versée"])},
      "RECOMMENDED_DEBIT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date optimale de prélévement"])},
      "RECOMMENDED_DEBIT_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant optimal de prélévement"])},
      "MONTHLY_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant mensuel"])},
      "MONTH_OUTCOMES_RENTS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant min. des loyers"])},
      "MONTH_OUTCOMES_RENTS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant max. des loyers"])},
      "MONTH_OUTCOMES_UTILITIES_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant min. des services"])},
      "MONTH_OUTCOMES_UTILITIES_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant max. des services"])},
      "MONTH_OUTCOMES_CREDITS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant minimal"])},
      "MONTH_OUTCOMES_CREDITS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant maximal"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de prélèvements min/mois"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de prélèvements max/mois"])},
      "BANK_CARD_AND_DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte bancaire & Prélèvements"])},
      "DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prélèvements"])},
      "BANK_CARD_PRESENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte(s) bancaire(s) liée(s)"])},
      "BANK_CARD_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité de cartes ayant réalisées des transactions sortantes"])},
      "BANK_CARD_NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéros de cartes identifiés pour les transactions sortantes"])},
      "MONTH_NEGATIVE_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date au plus tôt"])},
      "MONTH_NEGATIVE_BALANCE_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date au plus tard"])},
      "MAX_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date où le solde a été maximal sur la période"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part des dépenses sur les revenus"])},
      "MONTH_NEGATIVE_BALANCE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date moyenne du passage en rouge du compte"])},
      "AVERAGE_MONTHLY_ENTRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des entrées"])},
      "TOTAL_FOR_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total sur la période"])},
      "AVERAGE_MONTHLY_TAX_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des impôts"])},
      "AVERAGE_MONTHLY_EXITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des sorties"])},
      "ANNUAL_NET_INCOME_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du revenu net annuel"])},
      "ANNUAL_REVENUE_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du chiffre d'affaire annuel"])},
      "MONTHLY_TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant mensuel des impôts"])},
      "AVERAGE_MONTHLY_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle de la masse salariale"])},
      "OTHER_ACCOUNTS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres comptes"])},
        "MONTH_INCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des épargnes entrantes"])},
        "MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des épargnes sortantes"])},
        "MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des transferts automatiques entrants"])},
        "MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne mensuelle des transferts automatiques sortants"])}
      }
    },
    "DEBT_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d'endettement"])},
    "MONTH_OUTCOMES_CREDITS_COUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de crédits en cours"])},
    "REMAINS_TO_LIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reste à Vivre"])},
    "START_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début de la période d'analyse financière"])},
    "END_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin de la période d'analyse financière"])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance mensuelle"])},
    "TOP_JOINT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Compte Joint"])},
    "TOP_RECOMMENDED_DATE_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date recommandée de débit"])},
    "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervision bancaire"])},
    "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de transactions"])},
    "MOVEMENT_SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif des mouvements"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "ACCOUNT_STATEMENT": {
      "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter le relevé de compte"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RELEVÉ(S) DE COMPTE(S)"])},
      "ACCOUNT": {
        "CHECKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPTE COURANT"])},
        "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPTE D'ÉPARGNE"])}
      },
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction en attente"])},
      "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée, veuillez lancer une nouvelle recherche"])},
      "PRIVACY_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour des raisons de sécurité et de protection des données personnelles, le relevé de compte d'une analyse OpenBanking n'est plus disponible passé un certain délai."])},
      "CATEGORY": {
        "INCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement publicitaire"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation"])},
          "ALLOWANCE": {
            "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation familiale"])},
            "UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation de chômage"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre allocation"])}
          },
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement audiovisuel"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile"])},
          "AUTOMOBILE": {
            "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien automobile"])},
            "PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stationnement automobile"])}
          },
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-transfert"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais bancaires"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte d'argent liquide"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chèque"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])},
          "CREDIT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit à la consommation"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit immobilier"])}
          },
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptomonnaie"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événement culturel"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat de données"])},
          "DEPOSIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation et charité"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éducation"])},
          "EDUCATION": {
            "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation à la conduite"])},
            "SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éducation scolaire"])}
          },
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de pénalité"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement de remise en forme"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation alimentaire"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert frauduleux"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux de hasard"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épicerie"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé et bien-être"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indemnité"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert international"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais d'intervention"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais juridiques"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loisirs"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soins des animaux de compagnie"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement à la lecture"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejet"])},
          "REJECT": {
            "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejet de crédit"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre rejet"])}
          },
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
          "RENT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location pour consommateur"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de logement"])}
          },
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire"])},
          "SALARY": {
            "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire régulier"])},
            "TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire temporaire"])}
          },
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épargne"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement aux services de sécurité"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de saisie"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement logiciel"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché boursier"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement télécom"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non payé"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilité"])},
          "UTILITY": {
            "ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'énergie"])},
            "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'eau"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre service public"])}
          },
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu vidéo"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
        },
        "OUTCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement publicitaire"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation"])},
          "ALLOWANCE": {
            "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation familiale"])},
            "UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocation de chômage"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre allocation"])}
          },
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement audiovisuel"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile"])},
          "AUTOMOBILE": {
            "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien automobile"])},
            "PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stationnement automobile"])}
          },
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-transfert"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais bancaires"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte d'argent liquide"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chèque"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit"])},
          "CREDIT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit à la consommation"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit immobilier"])}
          },
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptomonnaie"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événement culturel"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat de données"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation et charité"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éducation"])},
          "EDUCATION": {
            "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation à la conduite"])},
            "SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éducation scolaire"])}
          },
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de pénalité"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement de remise en forme"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation alimentaire"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert frauduleux"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux de hasard"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épicerie"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé et bien-être"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indemnité"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert international"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais d'intervention"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais juridiques"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loisirs"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soins des animaux de compagnie"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement à la lecture"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejet"])},
          "REJECT": {
            "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejet de crédit"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre rejet"])}
          },
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
          "RENT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location pour consommateur"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de logement"])}
          },
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire"])},
          "SALARY": {
            "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire régulier"])},
            "TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire temporaire"])}
          },
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Épargne"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement aux services de sécurité"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de saisie"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement logiciel"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché boursier"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement télécom"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non payé"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilité"])},
          "UTILITY": {
            "ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'énergie"])},
            "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'eau"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre service public"])}
          },
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu vidéo"])},
          "WITHDRAWAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
        }
      }
    },
    "RECOMMENDATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandations"])},
    "APPROVALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrégations"])},
    "INDEBTEDNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endettement"])},
    "HABITAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitat"])},
    "EXISTING_CREDIT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses crédits existants"])},
    "ALERTS": {
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "CAUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune alerte n'a été remontée"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes"])}
    },
    "SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["services"])},
    "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eau"])},
    "GAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gaz"])},
    "ELECTRICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["électricité"])},
    "BANK_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartes bancaires"])},
    "DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détection débits différés"])},
    "GLOBAL_TRANSACTION_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre total de transactions"])},
    "MONTH_NEGATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passage dans le rouge sur la période"])},
    "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte principal"])},
    "NO_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction de revenu n'a été détectée."])},
    "ALERT": {
      "ABSENCE_OF_INCOME": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de revenus"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction de revenu n'a été détectée"])}
      },
      "PRESENCE_OF_REJECTS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de rejets"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec des rejets a été détectée"])}
      },
      "PRESENCE_OF_UNPAIDS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence d'impayés"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec des impayés a été détectée"])}
      },
      "PRESENCE_OF_PENSIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de pensions"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec des pensions a été détectée"])}
      },
      "PRESENCE_OF_GAMBLINGS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de jeux d'argent"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec des jeux d'argent a été détectée"])}
      },
      "PRESENCE_OF_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence d'allocations"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec des allocations a été détectée"])}
      },
      "PRESENCE_OF_SEIZURE_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de frais de saisie"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec des frais de saisie a été détectée"])}
      },
      "MONTH_NEGATIVE_BALANCE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde négatif du mois"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons détecté un ou plusieurs jours avec un solde négatif"])}
      },
      "BANK_WITH_EXTREME_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque avec un niveau de risque extrême"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons détecté un ou plusieurs comptes d'une banque considérée comme extrêmement risquée"])}
      },
      "BANK_WITH_HIGH_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque avec un niveau de risque élevé"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons détecté un ou plusieurs comptes d'une banque considérée comme très risquée"])}
      },
      "TRANSACTION_HISTORY_DATE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l'historique des transactions"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins de 2 mois d'historique de transactions ont été transmis pour cette analyse"])}
      },
      "TRANSACTION_HISTORY_DATE_ONE_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l'historique des transactions"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins d'1 mois d'historique des transactions a été transmis pour cette analyse"])}
      },
      "PRESENCE_OF_CASH_COLLECTIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence d'entreprises de recouvrement"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec une entreprise de recouvrement détectée"])}
      },
      "PRESENCE_OF_CRYPTOCURRENCIES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de cryptomonnaies"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec des cryptomonnaies a été détectée"])}
      },
      "TRANSACTION_HISTORY_COUNT": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'opérations dans l'historique des transactions"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins de 10 transactions ont été transmises pour cette analyse"])}
      },
      "PRESENCE_OF_CREDITS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de crédits par mois"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 3 transactions de crédit par mois ont été détectées"])}
      },
      "PRESENCE_OF_INTERVENTION_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de frais d'intervention"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec des frais d'intervention a été détectée"])}
      },
      "PRESENCE_OF_WITHDRAWALS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de retraits par mois"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 7 transactions de retrait par mois ont été détectées"])}
      },
      "PRESENCE_OF_INTERNATIONALS_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de virements internationaux"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins 1 transaction avec des virements internationaux a été détectée"])}
      },
      "TRANSACTION_HISTORY_FOR_ANALYSIS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des transactions à des fins d'analyse."])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de transactions transmises n'est pas suffisant pour permettre une analyse en temps utile."])}
      },
      "ABSENCE_OF_TAXE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de taxe"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction fiscale n'a été détectée"])}
      },
      "LOSS_OF_SALARY": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perte de salaire"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perte de salaire détectée"])}
      }
    },
    "NET_INCOME_AND_ANNUAL_TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenu net & chiffre d'affaire annuels"])},
    "TAXES_AND_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôts & masse salariale"])},
    "DAILY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance quotidienne"])},
    "MONTHLY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance mensuelle"])},
    "DAILY_AND_MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance quotidienne et mensuelle"])},
    "WRONG_ANALYSIS_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, l'analyse a été effectuée sur un compte particulier et non professionnel"])},
    "WRONG_ANALYSIS_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, l'analyse a été effectuée sur un compte professionnel et non particulier"])},
    "PROVIDER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue durant la récupération des données, merci de recommencer le dossier."])}
  },
  "SOCIAL_FACE_MATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche images"])},
    "CATCHED_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images récupérées"])},
    "NO_IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune image trouvée"])}
  },
  "AMLCFT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lutte contre le blanchiment et le terrorisme"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gel des avoirs"])},
    "PPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne politiquement exposée"])},
    "PPE_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette personne est identifiée comme exposée politiquement"])},
    "ADVERSE_MEDIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse"])},
    "ADVERSE_MEDIA_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe plusieurs articles de presse concernant le demandeur."])},
    "ADVERSE_MEDIA_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de presse"])},
    "COLUMN_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "SANCTIONED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanctionnée"])},
    "SANCTIONED_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette personne a été identifiée comme sanctionnée."])},
    "SANCTIONED_EXPLANATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela peut concerner un gel de ses avoirs, un lien avec le terrorisme et/ou le blanchiment."])},
    "CLASS_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chefs d'État, membres de l'exécutif national, membres des législatures nationales, hauts fonctionnaires de l'armée et de l'application de la loi, hauts fonctionnaires des agences nationales, membres des conseils d'administration des banques centrales, hauts fonctionnaires de la justice (niveau national), Top -hauts responsables des partis politiques"])},
    "CLASS_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres du conseil d'administration des organisations internationales et des fédérations sportives internationales, ambassadeurs, hauts commissaires et autres postes diplomatiques de haut niveau, membres de l'exécutif régional, membres des assemblées législatives régionales, hauts responsables judiciaires (niveau régional)"])},
    "CLASS_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction générale et conseil d'administration d'entreprises et d'organisations publiques"])},
    "CLASS_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maires et membres des collectivités locales (niveau sous-régional)"])},
    "LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "ADVERSE_MEDIA_DETAILS": {
      "financial-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crime financier"])},
      "violent-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crime violent"])},
      "sexual-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crime sexuel"])},
      "terrorism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrorisme"])},
      "fraud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraude"])},
      "narcotics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stupéfiants"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Général"])},
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriété"])},
      "financial-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Financier"])},
      "fraud-linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié à la fraude"])},
      "narcotics-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Stupéfiants"])},
      "violence-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Violence"])},
      "cybercrime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyber-criminalité"])},
      "general-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Général"])},
      "regulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réglementaire"])},
      "financial-difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficultés fiancières"])},
      "violence-non-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non AML CFT Violence"])},
      "other-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre Fiancière"])},
      "other-serious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre Sérieux"])},
      "other-minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre Mineur"])}
    },
    "LINKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liens"])},
    "NO_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette personne n'est ni identifiée comme sanctionnée ni identifiée comme exposée politiquement."])}
  },
  "TOOLTIP": {
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société ayant une procédure judiciaire en cours."])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société mise en sommeil et réactivée depuis."])},
    "MOBILE_PHONE_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du téléphone mobile."])},
    "SOCIAL_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images trouvées sur Google en lien avec les informations saisies."])},
    "EMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de l'e-mail."])},
    "AVAILABLES_PICTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images disponibles sur les réseaux sociaux par rapport aux informations du demandeur."])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'établissements détenus par la société"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui si il y a un gel des avoirs en cours."])},
    "SCORE_WEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Fraude sur l'existence et la fiabilité des données de contact du demandeur."])},
    "SCORE_SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Risque sur l'analyse financière et la solvabilité de l'entreprise."])},
    "DEFAULT_PROBABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesure du risque de défaillance de la structure."])},
    "SCORE_LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores mis à jour le"])},
    "SCORE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le score évalue la solidité financière de l'entreprise, en fonction de son secteur d'activité et de ses bilans financiers."])},
    "SCORE_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Risque sur l'analyse financière du compte bancaire."])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance mensuelle moyenne sur la période étudiée."])},
    "EMAIL_DISPOSABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail jetable"])},
    "EMAIL_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail risqué"])},
    "MOBILE_PHONE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone lié"])},
    "EMAIL_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail lié"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer la saisie du dossier"])},
    "VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter le dossier"])},
    "DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer le dossier"])},
    "DELETE_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le dossier"])},
    "BLOCK_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de reprendre un dossier terminé."])}
  },
  "EMAIL_DISPOSABLE": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email fourni est rattaché à un nom de domaine jetable."])}
  },
  "DOCUMENT_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôler les documents"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un type de document"])},
    "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce d'identité"])},
    "ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce d'identité"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permis de conduire"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport"])},
    "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de séjour"])},
    "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Compagnie ou de situation"])},
    "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS Avis de situation"])},
    "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrait de Kbis"])},
    "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticité - Analyse de la non-altération numérique"])},
    "AUTHENTICITY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'authenticité"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale"])},
    "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale simplifiée"])},
    "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale complexe"])},
    "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis d'imposition - Revenus ou fonciers"])},
    "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôt sur le revenu"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre type"])},
    "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôt foncier"])},
    "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
    "BLOC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle des documents"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analyse de la pièce a échoué"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de terminer le téléversement de votre document dans la fenêtre de gauche pour pouvoir consulter l’analyse du document."])},
    "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulletin de paie"])},
    "PAYSLIP_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche de paie"])},
    "RIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
    "KBIS_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS"])},
    "TAX_NOTICE_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis d'imposition"])},
    "OTHER_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre document"])},
    "NEW_DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser un nouveau document"])},
    "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse identité"])},
    "ID_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez lancer ici une analyse documentaire sur les pièces d'identité comme les cartes d'identité nationale, les passeports, les titres de séjour, les permis de conduire."])},
    "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse documentaire"])},
    "DOCUMENT_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez lancer ici une analyse documentaire pour une liasse fiscale, un KBIS, une fiche de paie, un avis d'imposition ou tout autre document de type PDF."])},
    "SELECT_DOCUMENT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un ou plusieurs types de document"])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])},
    "UNKNOWN_DOC_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document que vous avez passé n'a pas pu être identifié comme une pièce d'identité. Pour le contrôler, pensez à uploader le recto et le verso du document ou utiliser le volet d'analyse documentaire."])},
    "NO_DOCUMENT_UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document uploadé."])},
    "TABLE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformité"])},
      "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticité"])},
      "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle analyse d'identité"])},
      "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle analyse documentaire"])},
      "RESULT": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'analyse"])},
        "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
        "WAITING_CONFIRMATION_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse en attente de confirmation de votre part"])},
        "IN_PROGRESS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat en cours d'analyse"])},
        "LIVE_CHECK": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclenchement de la levée de doutes"])},
          "DESC_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de déclencher la levée de doutes du document <", _interpolate(_named("documentName")), ">."])},
          "DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vérification humaine n'est disponible que pendant les 10 premières minutes après l'upload du document."])},
          "DESC_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le traitement de l'analyse peut prendre du temps, une notification vous sera envoyé une fois l'analyse terminée."])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Levée de doutes vous permet de demander une vérification manuelle du document par un opérateur humain en moins de quinze minutes. Attention, elle n'est uniquement disponible que durant 10min après l'upload du document."])},
          "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de doutes en cours de traitement"])},
          "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de la levée de doutes disponible"])},
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de doutes en cours de traitement."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les contrôles ci-dessous seront mis à jour une fois l'analyse manuelle effectuée. Merci de patienter."])},
          "FAILED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de la levée de doutes du document non disponible."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur technique est survenue lors du déclenchement de l'analyse de la levée de doutes du document."])},
          "SUCCESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de la levée de doutes du document réalisée."])},
          "ANALYSIS_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat de l'analyse"])},
          "ANALYST_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message de l'analyste"])}
        },
        "CHECKS": {
          "EMISSION_COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence nationalité du document"])},
          "EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence type de doc / date d’emission"])},
          "EXPIRATION_DATE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité de la date d'expiration"])},
          "VALIDITY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document en cours de validité"])},
          "MRZ_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle MRZ"])},
          "MRZ_CHECKSUMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle MRZ"])},
          "MRZ_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle MRZ"])},
          "MRZ_ALIGNEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle MRZ"])},
          "MRZ_CLASSIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle MRZ"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence identité de la personne / MRZ"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence identité de la personne / MRZ"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence identité de la personne / MRZ"])},
          "DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de document cohérent avec la MRZ"])},
          "EXPIRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’expiration cohérente avec la MRZ"])},
          "OCR_EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’émission cohérente avec la MRZ"])},
          "PERSONAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d’identification personnel cohérente avec la MRZ"])},
          "PHOTO_CONFORMITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de la photo"])},
          "PHOTO_LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de la photo"])},
          "VISUAL_SECURITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformité des points de sécurité du document"])},
          "PHOTOCOPY_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détection photocopie"])},
          "SCREENSHOT_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détection screenshot"])},
          "OLD_DRIVING_LICENSE_DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro permis ancien format"])},
          "DRIVING_LICENSE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle validité du permis"])},
          "DRIVING_LICENSE_DIGITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle validité du permis"])},
          "AGE_AT_CERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge de l'obtention cohérent"])},
          "MRZ_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle MRZ"])},
          "MRZ_IDENTITY_COHERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence identité de la personne"])},
          "PICTURE_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de la photo"])},
          "BARCODE_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code-barres trouvé"])},
          "BARCODE_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données du code-barres correctement encodées"])},
          "BARCODE_SIGNATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature du code-barres valide"])},
          "TYPE_RECOGNIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type de document a été reconnu"])},
          "NOT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration"])},
          "MRZ_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La zone lisible par machine (MRZ) du document est valide"])},
          "VALIDITY_PERIOD_MODEL_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modèle de période de validité correspond"])},
          "OCR_ANALYSIS_MRZ_FIRST_NAMES_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les prénoms dans la MRZ correspondent à l'analyse OCR"])},
          "OCR_ANALYSIS_MRZ_DOCUMENT_NUMBER_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de document dans la MRZ correspond à l'analyse OCR"])},
          "DOCUMENT_ASPECT_GENERAL_APPEARANCE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'apparence générale du document correspond aux attentes"])},
          "SECURITY_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éléments de sécurité présents dans le document"])},
          "QUALITY_ABOVE_MINIMUM_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La qualité du document est supérieure au seuil minimum"])},
          "NOT_SPECIMEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document n'est pas un spécimen"])},
          "FRONT_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le recto du document a été trouvé"])},
          "OCR_ANALYSIS_MRZ_BIRTH_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de naissance dans la MRZ correspond à l'analyse OCR"])},
          "OCR_ANALYSIS_MRZ_BIRTH_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de naissance dans la MRZ correspond à l'analyse OCR"])},
          "OCR_ANALYSIS_FAITHFUL_FONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analyse OCR indique l'utilisation d'une police de caractères fidèle dans le document"])},
          "DOCUMENT_ASPECT_PICTURE_PRESENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une image est présente sur le document"])},
          "OCR_ANALYSIS_EXPIRATION_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date d'expiration a été lue avec succès grâce à l'analyse OCR (Reconnaissance Optique de Caractères)"])},
          "OCR_ANALYSIS_ISSUANCE_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de délivrance a été lue avec succès grâce à l'analyse OCR"])},
          "ISSUANCE_AND_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les dates de délivrance et d'expiration correspondent"])},
          "NO_TAMPERING_SIGN_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun signe de falsification n'a été trouvé sur le document"])},
          "NO_ANNOTATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune annotation n'a été trouvée sur le document"])},
          "BACK_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le verso du document a été trouvé"])},
          "OCR_ANALYSIS_MRZ_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date d'expiration dans la MRZ correspond à l'analyse OCR"])},
          "OCR_ANALYSIS_MRZ_ISSUANCE_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de délivrance dans la MRZ correspond à l'analyse OCR"])},
          "OCR_ANALYSIS_MRZ_LAST_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom de famille dans la MRZ correspond à l'analyse OCR"])},
          "DOC_2D_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D Doc valide"])},
          "DOC_2D_AND_DOCUMENT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D Doc cohérent avec les informations du document"])},
          "WORKABLE_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document peut être traité pour vérification"])},
          "WORKABLE_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'élément peut être traité pour vérification"])},
          "STATUS_ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier accepté"])},
          "FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document frauduleux"])},
          "DOCUMENT_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La qualité de la capture des documents ne permet pas le contrôle humain"])},
          "DOCUMENT_INCORRECTLY_FRAMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document ne rentre pas dans le cadre de l’image"])},
          "DOCUMENT_SIDE_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une seule face du document est visible (recto et verso obligatoire)"])},
          "DOCUMENT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document expiré"])},
          "DOCUMENT_UNSUPPORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type de document n’est pas pris en charge"])},
          "DOCUMENT_COUNTRY_UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le pays d’émission du document n’est pas pris en charge"])},
          "DOCUMENT_UNSUPPORTED_RECEIPT_ACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type de document n’est pas pris en charge"])},
          "BIOMETRY_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La qualité de la donnée biométrique ne permet pas le contrôle humain"])},
          "LIVE_CHECK_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur technique survenu chez le prestataire"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre raison, voir le détail ci-dessous"])}
        },
        "KBIS": {
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'enregistrement"])},
          "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
          "HEAD_QUARTER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse siège social"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
          "PUBLICATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date publication KBIS"])},
          "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité du membre"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
          "IDENTIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification"])},
          "MAIN_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité principale"])},
          "MEMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
          "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne(s) physique(s)"])},
          "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne(s) morale(s)"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "BIRTH_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d’usage"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
          "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie juridique"])},
          "COMPANY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
          "ESTABLISHMENT_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement"])}
        },
        "TAX_REPORT": {
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information entreprise"])},
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
          "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de clôture de l'exercice"])},
          "OPENING_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'ouverture de l'exercice"])},
          "CONFIDENTIALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document confidentiel"])},
          "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de durée de l'exercice"])},
          "RATIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratios financiers"])},
          "MONTH": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " mois"]), _normalize([_interpolate(_named("n")), " mois"])])}
        },
        "TAX_NOTICE": {
          "TAX_ADRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse fiscale"])},
          "BANKID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant bancaire"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de compte"])},
          "REMAINING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde restant"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de naissance"])},
          "NUMBER_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de parts"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation familiale"])},
          "CHECKS": {
            "FOUND_2D_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D-DOC identifié"])},
            "NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence des noms"])},
            "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence de la date d'établissement"])},
            "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence du revenu de référence"])},
            "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence du numéro fiscal"])},
            "REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence de la référence"])},
            "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence de l'année de référence"])},
            "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence du nombre de parts"])},
            "NUMBER_OF_REGISTRANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cohérence du nombre de déclarants"])}
          }
        },
        "AUTHENTICITY": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticité"])},
          "DETAILED_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'analyse"])},
          "NO_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune alerte n'est remontée de l'analyse du document."])},
          "BINARY_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document modifié depuis sa création"])},
          "AUTOGENERATED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce document provient d'un logiciel générateur de documents falsifiés"])},
          "METADATA_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analyse des méta-données révèle une modification du document"])},
          "PIXEL_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une partie de l'image a été modifiée"])},
          "SOFTWARE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier logiciel est un logiciel de retouche photo (comme Photoshop), il peut être utilisé pour redimensionner (pour qu'elle soit authentique) ou modifier l'image."])},
          "REPORT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport détaillé Finovox (PDF)"])},
          "ANALYSIS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les analyses d'authenticité peuvent prendre plusieurs minutes."])},
          "COHERENCE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document n'a aucune cohérence en termes de données que nous collectons."])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue durant l’océrisation. Merci de vérifier l’extension de votre fichier (pdf, jpg, jpeg, png autorisé) avant de réessayer. En cas de problème persistant, merci de contacter le support."])}
        },
        "PROPERTY_TAX": {
          "PAYMENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations paiement"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations paiement"])},
          "LEGAL_DEBTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débiteurs légaux"])},
          "PROPERTIES_AND_BUILDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétés et bâtiments"])},
          "PAYMENT_DEADLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite de paiement"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant restant à payer"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant restant à rembourser"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant bancaire"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du créancier"])},
          "TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de la taxe"])},
          "IDENTIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant"])},
          "RIGHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit"])},
          "DESIGNATION_AND_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désignation et adresse"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'avis d'imposition"])},
          "ADDRESSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarant"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro fiscal"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de l'avis"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de rôle"])},
          "TAX_DEPARTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Département d'imposition"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de propriétaire"])},
          "TAX_COMMUNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commune d'imposition"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du service"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'établissement"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de mise en recouvrement"])}
        },
        "INCOME_TAX": {
          "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'avis d'imposition"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'avis d'imposition"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "FIP_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro FIP"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID du service"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification fiscale"])},
          "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenu fiscal de référence"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarant"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de rôle"])},
          "TAX_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse fiscale"])},
          "PAYMENT_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de paiement"])},
          "FAMILY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du ménage"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant restant à payer"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de naissance"])},
          "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de parts"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation familiale"])},
          "MANDATE_UNIQUE_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence Mandat Unique"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant restant à rembourser"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du créancier"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de la banque"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de l'avis"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de propriétaire"])},
          "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenu fiscal de référence"])}
        },
        "BANK_DETAILS": {
          "BANK_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code banque"])},
          "BRANCH_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code guichet"])},
          "ACCOUNT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de compte"])},
          "KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clé RIB"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
          "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la banque"])},
          "BANK_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de la banque"])},
          "IBAN_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité de l'IBAN"])},
          "ACCOUNT_NUMBER_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité du numéro de compte"])},
          "DOCUMENT_ANALYZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document analysé"])},
          "DIRECT_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domicilation"])},
          "CHECKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifications"])}
        },
        "PAYSLIP": {
          "PAYSLIP_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information sur le bulletin de paie"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information de paiement"])},
          "HIRE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’embauche"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "NET_PAYABLE_BEFORE_INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net à payer avant impôt"])},
          "NET_PAYABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net à payer après impôt"])},
          "PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de paiement"])}
        }
      }
    },
    "NO_PERSON_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucunes personnes trouvées"])},
    "ERROR_MESSAGE_API": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème a été rencontré"])}
  },
  "SCORE": {
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "WEB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "SECTOR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur"])},
    "COMPANY_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "DEFAULT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défaut"])},
    "REQUEST_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request ID"])},
    "HELPER_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous conseillons de procéder à des contrôles supplémentaires."])},
    "LOCAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score local "])},
    "INTERNATIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score international"])}
  },
  "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse financière de l'entreprise"])},
  "TAX_REPORTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale"])},
    "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée d'exercice"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de clôture"])},
    "TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiffre d'affaire"])},
    "NET_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultat net"])},
    "AUTO_FINANCE_CAPACITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capacité d'auto-financement"])},
    "NET_MARGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marge nette"])},
    "FB_TB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Structure financière"])},
    "FINANCIAL_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité"])},
    "WORKING_CAPITAL_REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin de fonds de roulement"])},
    "WORKING_CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds de roulement"])},
    "FINANCIAL_SOLDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde financier"])},
    "DEBTS_SHORT_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette courte terme / bilan"])},
    "DEBTS_MIDDLE_LONG_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette moyen long terme / bilan"])},
    "NOT_AVAILABLE_NEED_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse non disponible. Vous pouvez en charger une pour obtenir son analyse."])}
  },
  "FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponible"])},
  "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indisponible"])},
  "NO_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non disponible"])},
  "ENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entité"])},
  "ENTITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entités"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
  "OF_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du mois"])},
  "EFFECTIVE_RECIPIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaire effectif"])},
  "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employé"])},
  "REPRESENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représentant"])},
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
  "LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores mis à jour le"])},
  "VERIFICATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existant"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de l'existence de l'information avec le demandeur"])}
  },
  "RELATED": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien suffisant"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de l'association de l'information avec le demandeur"])}
  },
  "FINISH": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloturer de dossier"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez vous récupérer la synthèse PDF avant de clôturer la page ?"])}
  },
  "FUNCTION": {
    "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur général"])}
  },
  "SOCIAL_NETWORKS": {
    "MAIL_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail lié"])},
    "PHONE_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone lié"])},
    "ADDRESS_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse liée"])},
    "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié à l'e-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié au téléphone"])},
    "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux sociaux"])},
    "INFORMATIONS": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
      "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])}
    }
  },
  "TELEPHONE": {
    "LINETYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
    "CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur actuel"])},
    "ORIGINAL_CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operateur initial"])},
    "GEOLOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation"])},
    "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro"])}
  },
  "HISTORIC_PAGE": {
    "HISTORIC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des dossiers"])},
    "B2B_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse entreprise"])},
    "B2C_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse particulier"])},
    "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
    "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 derniers jours"])},
    "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])}
  },
  "TABLE": {
    "PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par page"])},
    "FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
    "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthèse"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "MARKET_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de marché"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journey Id"])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "NUMBER_OF_TAX_NOTICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'avis d'impositions"])},
    "NUMBER_OF_TAX_REPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de liasses fiscales"])},
    "NUMBER_OF_ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de pièces d'identité"])},
    "NUMBER_OF_PASSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de passports"])},
    "NUMBER_OF_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de Kbis"])},
    "NUMBER_OF_BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de RIB"])},
    "NUMBER_OF_DRIVING_LICENSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de permis de conduire"])},
    "NUMBER_OF_RESIDENCE_PERMITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de titres de séjour"])},
    "NUMBER_OF_PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de bulletin de paie"])},
    "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
    "MAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
    "POSTAL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse postale"])},
    "TELEPHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Fraude"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Entreprise"])},
    "TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "STATUS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etat du dossier"])},
    "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison sociale"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
    "OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur"])},
    "SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthèse"])},
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "FILE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de dossier"])},
    "DATE_AND_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure"])},
    "DOCUMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de document"])},
    "DOCUMENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du document"])}
  },
  "REGEX": {
    "NOT_NULL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne doit pas être vide."])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro n'est pas valide."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email n'est pas valide."])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le format doit être JJ/MM/AAAA."])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doit comporter 9 chiffres."])},
    "NATIONAL_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doit comporter 10 chiffres."])},
    "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date invalide."])},
    "LETTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doit comporter des lettres."])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doit être au format IBAN."])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doit comporter uniquement des lettres"])},
    "UPPER_CASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne doit comporter que des majuscules, des chiffres et des underscores."])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien n'est pas valide"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne doit comporter que des chiffres"])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne peut contenir que des lettres, des chiffres et les symboles _+%-"])}
  },
  "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
  "FIRST_SEEN_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traces d'utilisation sur le web"])},
  "NUMBER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro identifiant entreprise"])},
  "EMPLOYER_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification Employeur"])},
  "PROCESS_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processus de lancement openbanking"])},
  "INFORMATION_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'entreprise"])},
  "SCORING_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score confiance entreprise"])},
  "EMAIL_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine de l'email"])},
  "EMAIL_DOMAIN_CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création du domaine l'email"])},
  "EMAIL_DOMAIN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien avec le domaine de l'email"])},
  "EMAIL_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien avec l'email"])},
  "OTHER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possession d'autre entreprise"])},
  "IS_RECENTLY_ACQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récemment acquise"])},
  "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores financiers"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "EMAIL_CORRELATION_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondance entre le domaine de l’email et du site web"])},
  "CHECK_IBAN": {
    "CONTAINER": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification IBAN"])},
      "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])}
    },
    "RESULT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse d'IBAN"])},
      "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifications"])},
      "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion avec la banque"])},
      "typeTitulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type titulaire"])},
      "dateNaissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "autreNom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre nom"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La demande de vérification n’a pas pu être réalisée car un contrôle technique a échoué"])},
      "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'IBAN existe"])},
      "IBAN_NOT_EXISTS_ANYMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'IBAN n'existe plus et le compte a été fermé il y a moins de 13 mois"])},
      "IBAN_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'IBAN n'existe pas ou le compte a été fermé depuis plus de 13 mois"])},
      "IBAN_CAN_BE_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’IBAN ne peut pas être vérifié : L’utilisateur a demandé que son compte ne soit pas contrôlable avec DIAMOND ou le compte est en devise étrangère ou le compte n’est pas compatible avec des opérations de type prélèvements et virements, et est donc or scope, ..."])},
      "HOLDER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type de titulaire renseigné est correct"])},
      "HOLDER_TYPE_INCORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type de titulaire renseigné est incorrect"])},
      "SIREN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Numéro d'identification renseigné correspond bien à l’IBAN contrôlé"])},
      "SIREN_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro d'identification renseigné ne correspond pas à l’IBAN contrôlé"])},
      "SIREN_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La banque n’est pas en mesure de contrôler le numéro d'identification (par exemple parce qu’elle ne le connaît pas)"])},
      "BIRTHDATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de naissance renseignée correspond bien à l’IBAN contrôlé"])},
      "BIRTHDATE_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La date de naissance renseignée ne correspond pas à l’IBAN contrôlé"])},
      "BIRTHDATE_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La banque n’est pas en mesure de contrôler la date de naissance renseignée (par exemple parce qu’elle ne le connaît pas)"])},
      "REASON_CODE": {
        "IncorrectAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN invalide"])},
        "UnrecognisedInitiatingParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX du Donneur d’Ordre"])},
        "InvalidIdentificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
        "MissingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "InvalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données invalides"])},
        "BICInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque non éligible au contrôle SEPAMAIL"])}
      }
    },
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semblerait que la banque interrogée rencontre un problème de connexion à SepaMail, merci de votre compréhension."])}
  },
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
  "SYNTHESIS": {
    "FOLDER_STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État du dossier"])},
    "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyste"])},
    "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié"])},
    "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifié"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant unique"])},
    "PHONE_AND_SOCIAL_NETWORKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone et réseaux sociaux"])},
    "LINKED_TO_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié à l'adresse mail"])},
    "LINKED_TO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lié au téléphone"])},
    "BUSINESS_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de d'identification"])},
    "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification"])}
  },
  "WARNING_LIST": {
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de société"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
    "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune alerte remontée lors de notre analyse"])},
    "MESSAGE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, le(s) paramètre(s) suivant(s) a/ont été identifié(s) lors d'un précédent cas de fraude"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning List Meelo"])},
    "MESSAGE_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, le paramètre saisi a été identifié lors d'un précédent cas de fraude."])},
    "MESSAGE_NO_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune alerte remontée lors de notre analyse."])}
  },
  "SELECT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner les analyses que vous souhaitez lancer"])},
  "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
  "PHONE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de sélectionner un indicateur et de saisir un numéro sans espace ou caractère entre les numéros."])},
  "BIRTHDATE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortement recommandé pour afficher l'écosystème du client."])},
  "EXAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du"])},
  "DOMAIN_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le domaine"])},
  "DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine"])},
  "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarant"])},
  "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergeur"])},
  "ADDITIONAL_CONTROLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôles supplémentaires"])},
  "COMPANY_DOMICILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise domiciliée"])},
  "EMAIL_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création du domaine de l’email"])},
  "WEBSITE_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création du domaine du site web"])},
  "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d'inactivité supérieure à 3 ans"])},
  "LOCATION_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation à risque"])},
  "OPERATOR_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur à risque"])},
  "INITIAL_RISKY_OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur initial à risque"])},
  "NO_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour plus d’informations, veuillez lancer les scores"])},
  "HAS_OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre entreprise en procédure collective"])},
  "HAS_OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre entreprise radiée"])},
  "HAS_OTHER_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre entreprise"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "SUPPORT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème ? N'hésitez pas à nous contacter"])},
  "SIDEBAR": {
    "KIT_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse Particulier"])},
    "KIT_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse Entreprise"])},
    "KIT_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "KIT_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse Document"])},
    "HISTORICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d'aide"])},
    "KIT_RECOVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recouvrement"])},
    "MEELO_KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcours Meelo"])},
    "BACKOFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back office"])},
    "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail"])},
    "MEELO_ACADEMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo academy"])},
    "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de document(s)"])},
    "USERS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes utilisateurs"])},
      "PROFILE_AND_ACCESS_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des profils & accès"])},
      "USER_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des utilisateurs"])},
      "GROUP_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des groupes"])},
      "LOCATION_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des places"])}
    },
    "LIST_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des listes"])},
    "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "MONITORING": {
      "MY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes consommations"])},
      "COMPANY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conso de la compagnie"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring des conso"])}
    },
    "RULES_ENGINE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moteur de règles"])},
      "COMPANY_RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles de la compagnie"])},
      "SETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensembles de règles"])}
    },
    "COMPANY": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma compagnie"])},
      "MANAGE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des listes"])}
    }
  },
  "BREADCRUMBS": {
    "COMPANY_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de l'entreprise"])},
    "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandeur"])},
    "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
    "JUSTIFICATORIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justificatifs"])},
    "SCORE_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du score"])},
    "RECEIVER_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du receveur"])},
    "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléversement"])},
    "BATCH_RUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécution du batch"])}
  },
  "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projet"])},
  "PRODUCT": {
    "VEHICLES_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur TTC des véhicules"])},
    "CONTRIBUTION_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de l'apport"])}
  },
  "CGI": {
    "DECISION": {
      "SUCCESS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière étape !"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer à la collecte des justificatifs"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer et continuer"])}
      },
      "FAILED": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analyse est terminée !"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis défavorable"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])}
      }
    },
    "DOCUMENTS": {
      "BLOCK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement des justificatifs"])}
      },
      "ID_CARD": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce d'identité"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recto et verso de votre pièce d’identité"])}
      },
      "BANK_DETAILS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB de votre compte principal"])}
      },
      "KBIS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS datant de moins de 3 mois"])}
      },
      "TAX_REPORT": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière liasse fiscale, avec au moins 2 ans d’historique"])}
      }
    },
    "CONGRATULATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bravo"])},
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez fini de compléter le dossier sur le portail Meelo."])}
  },
  "TITLE_ACTION_DOCUMENT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode d'acquisition"])},
  "ONLY_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF uniquement"])},
  "MULTI_REQUESTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-demande"])},
    "DESCRIPTION_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au cours des 30 derniers jours, un ou plusieurs dossiers ont été créés par"])},
    "DESCRIPTION_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec des informations similaires et/ou identiques."])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun dossier similaire ou identique saisi lors des 30 derniers jours."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de dossiers avec le même e-mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de dossiers avec le même téléphone"])},
    "REGISTRATIONNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de dossiers avec le même numéro d'identification"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de dossiers avec le même iban"])}
  },
  "RECOVERY": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant client"])},
    "REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande"])},
    "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse financière"])},
    "ANALYSIS_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de réalisation de l'analyse"])},
    "BANK_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de la banque"])},
    "CUSTOMER_RELIABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiabilité client"])},
    "REJECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejet"])},
    "ON_AVERAGE_PER_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en moyenne par mois"])},
    "INTERVENTION_FEES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais d’intervention"])},
    "THIRD_PARTY_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis tiers détenteur"])},
    "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux d’argent"])},
    "FINANCIAL_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation financière"])},
    "AVERAGE_DISPOSABLE_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reste à vivre moyen"])},
    "LOWEST_DISPOSABLE_INCOME_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reste à vivre le plus bas sur la période"])},
    "LOWEST_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenu le plus bas"])},
    "LACK_OF_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence de revenu"])},
    "AVERAGE_MONTHLY_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenu moyen mensuel"])},
    "FINANCIAL_PRESSURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pression financière"])},
    "CREDITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédits"])},
    "DEBT_COLLECTION_AGENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociétés de recouvrement"])},
    "RISK_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau de risque"])},
    "LOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faible"])},
    "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modéré"])},
    "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risqué"])},
    "LACK_OF_INCOME_SOURCES_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une ou plusieurs source de revenu ont été détectées"])},
    "LACK_OF_INCOME_SOURCES_NOT_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun revenu détecté"])}
  },
  "BATCH": {
    "CLICK_ON_SCORES_BELOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur un ou plusieurs score(s) ci-dessous pour choisir les scores à lancer via le batch."])},
    "MANUAL_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode d'emploi"])},
    "MANUAL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour une utilisation optimale du batch, nous vous conseillons de télécharger le template proposé ci-dessus, il sera composé de la structure idéale adaptée pour l'ensemble des scores sélectionnés précédemment. Vous pourrez ensuite remplir le fichier excel avec vos données, puis les téléverser un peu plus bas dans la page."])},
    "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation du Batch réussie"])},
    "DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le résultat du batch"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancement du Batch en cours..."])},
    "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous venez de recevoir un email avec le résultat du batch"])},
    "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer le batch"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler le batch"])},
    "USAGE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information d'utilisation"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "DOWNLOAD_TEMPLATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le template"])},
    "LIMIT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lignes / fichier"])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du fichier de sortie souhaité"])},
    "SCORE": {
      "REQUEST_ID": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Fraude Identité"])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Fraude Identité Professionnelle"])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Confiance Entreprise"])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Evaluation du Risque Secteur Energie"])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score E-Réputation"])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Risque"])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Altares"])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Ellisphère"])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score CréditSafe"])}
      },
      "DESCRIPTION": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Score Fraude Identité est un modèle qui permet d'évaluer le risque de fraude à l’identité d’un client. Il est basé sur l'évaluation de plusieurs critères : nom, prénom, e-mail, telephone mais également l’adresse postale ou la date de la date de naissance."])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Score Fraude Identité pro est un modèle qui permet d'évaluer le risque de fraude à l’identité d’un client professionnel (dans le cadre d’une relation B2B). Il est basé sur l'évaluation de plusieurs critères : nom, prénom, e-mail, telephone mais également l’adresse postale ou la date de la date de naissance."])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service de scoring “Score Confiance Entreprise” est un outil d'évaluation de l’entreprise et du lien entre le demandeur (personne physique) et l’entreprise (personne morale)."])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le “Score Risk Energie” est un modèle conçu spécialement pour le secteur de l’énergie qui permet d’évaluer le risque d’impayés du client. Il est basé sur l’évaluation de plusieurs critères : l’opérateur téléphonique / la Banque / les données IRIS sur l’adresse postale / le montant mensuel (exact ou estimé)"])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Score E-Réputation est un modèle conçu pour évaluer la satisfaction et la confiance des consommateurs envers la société en se basant sur les différents avis qu'ils ont laissés."])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Score Risque est un modèle conçu pour évaluer la solvabilité d'une personne en se basant sur ses informations personnelles ainsi que ses informations de paiement."])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Score Altares est un modèle conçu pour évaluer le risque de solvabilité d'une entreprise en se basant sur les résultats fournis par la société de cotations financières Altares."])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Score Ellisphère est un modèle conçu pour évaluer le risque de solvabilité d'une entreprise en se basant sur les résultats fournis par la société de cotations financières Ellisphère."])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Score CréditSafe est un modèle conçu pour évaluer le risque de solvabilité d'une entreprise en se basant sur les résultats fournis par la société de cotations financières CréditSafe."])}
      }
    }
  },
  "FILE": {
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un fichier"])}
  },
  "IMPORTANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important"])},
  "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risqué"])},
  "BACKOFFICE": {
    "GROUPS": {
      "COMPANY_GROUPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des groupes"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe :"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un groupe"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce groupe est utilisé par des utilisateurs, êtes vous sûr de vouloir le supprimer ?"])}
    },
    "PLACES": {
      "COMPANY_PLACES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des places"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la place :"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une place"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette place est utilisée par des utilisateurs, êtes vous sûr de vouloir la supprimer ?"])}
    },
    "USERS": {
      "SHORTCUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les raccourcis"])},
      "COMPANY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs de la compagnie"])},
      "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
      "PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
      "USER_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification d'un utilisateur"])},
      "PROFILE_ASSIGNMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributions de profils"])},
      "USER_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression d'un utilisateur"])},
      "DELETE": {
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de supprimer l’utilisateur"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de l'entreprise"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’utilisateur ne pourra plus accéder à son compte."])},
        "ACTION_IRREVERSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette action est irréversible."])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous certain de vouloir supprimer cet utilisateur ?"])}
      },
      "USER_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche utilisateur"])},
      "TECHNICAL_ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions techniques"])},
      "SUPERVISOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superviseur"])},
      "USER_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un utilisateur"])},
      "ACTIVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte actif"])},
      "UPDATE_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut du compte a bien été modifié."])},
      "RESET_PASSWORD": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation d'un mot de passe"])},
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point d'envoyer un mail de réinitialisation de mot de passe pour l'utilisateur"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à l'adresse mail"])},
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail de réinitialisation de mot de passe envoyé"])}
      },
      "RESET_API_KEY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation d'une clé d'API"])},
        "CONFIRM_RESET_USER": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point d'envoyer un mail de réinitialisation de clé d'API pour l'utilisateur"])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à l'adresse mail"])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important : Le lien pour récupérer la clé d'API n'est valable que 30 minutes."])}
        },
        "CONFIRM_RESET_US": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de réinitialiser votre clé d'API."])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par sécurité, la valeur de votre nouvelle clé ne pourra être récupérée qu'une seule fois."])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette nouvelle clé sera directement copier dans votre presse-papier numérique."])},
          "FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous conseillons de la coller immédiatement dans un endroit sécurisé afin de pouvoir la réutiliser ultérieurement."])}
        },
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail de réinitialisation de clé api envoyé"])},
        "NEW_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nouvelle clé d'api a été copiée dans votre presse-papier"])}
      }
    },
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compagnie"])},
    "FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonction"])},
    "ADVANCED_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur avancé"])},
    "ADVANCED_USER_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peut créer d'autres utilisateurs avancés et/ou simple et accéder à certains paramétrages."])},
    "PROFILE_AND_ACCESS": {
      "COMPANY_PROFILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profils de la compagnie"])},
      "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "MESSAGE_PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de sélectionner un profil ci-dessus afin de voir la liste des permissions qui lui sont associés."])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé par"])},
      "KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit"])},
      "MODULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
      "RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droits"])},
      "ADDING_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un profil"])},
      "PROFILE_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification d'un profil"])},
      "SAVE_PERMISSION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des modifications ont été observées, n'oubliez pas de les sauvegarder via le bouton ci-dessus."])},
      "DESCRIPTION_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du profil"])},
      "PROFILE_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du profil"])},
      "QUESTION_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous certain de vouloir supprimer ce profil ?"])},
      "MESSAGE_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout utilisateur lié à ce profil se verra retiré ses accès."])},
      "NO_PROFILE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun profil trouvé"])}
    },
    "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
    "MONITORING": {
      "DOWNLOAD_BALANCE_SHEET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le bilan"])},
      "TOTAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation totale"])},
      "MONTHLY_EVOLUTION_CURVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courbe de l'évolution mensuelle"])},
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aujourd'hui"])},
      "THIS_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette semaine"])},
      "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La semaine dernière"])},
      "THIS_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce mois-ci"])},
      "LAST_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mois dernier"])},
      "GENERAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation générale"])},
      "COMPANY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation pour la compagnie"])},
      "DATA_ON_THE_WAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos données sont en chemin..."])},
      "CONSUMPTION": {
        "OCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse documentaire d’identité"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse documentaire"])},
        "CHECK_VALUE_COMPANY_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification d’IBAN B2B"])},
        "CHECK_VALUE_PERSONAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification d’IBAN B2C"])},
        "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de l’entreprise"])},
        "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse LCB-FT"])},
        "GET_LINKEDIN_PROFILE_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrichissement LinkedIn"])},
        "OPENBANKING_MEELO_OPENBANKING_PERSONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2C"])},
        "OPENBANKING_MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2B"])},
        "SCORING_ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Ellisphère"])},
        "SCORING_CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score CréditSafe"])},
        "SCORING_WEB_SCORE_B2B_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Confiance Entreprise"])},
        "SCORING_WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Identité Particulier"])},
        "SCORING_E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score E-Réputation"])},
        "SCORING_ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Altares"])},
        "SCORING_SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Identité Professionnel"])},
        "SELECTED_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation des utilisateurs sélectionnés"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation de l'entreprise"])},
        "OF_MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation de mon équipe"])},
        "MY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma consommation"])}
      },
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée. Lancer une première analyse pour pouvoir consulter votre consommation."])}
    },
    "RULES_ENGINE": {
      "RULES": {
        "FOR_API_IMPLEMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour implémentation API"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous trouverez ici la liste des règles autorisées pour votre compagnie."])},
        "RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les règles"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "CONTAINED_IN_A_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présente dans un ensemble"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "GENERIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générique"])},
        "SPECIFIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifique"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "MODIFY_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification d'une règle"])},
        "RULE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la règle"])},
        "RULE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description de la règle"])},
        "VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])},
        "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur par défaut"])},
        "PARAMETERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
        "ARGUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arguments"])},
        "EDIT_PARAMETER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition du paramètre"])},
        "PARAMETER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du paramètre"])},
        "PARAMETER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description du paramètre"])},
        "FLOAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décimal"])},
        "INTEGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entier"])},
        "STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte"])},
        "BOOLEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booléen"])},
        "LIST_STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de textes"])},
        "RULE_PRESENT_IN_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règle présente dans les ensembles suivants"])},
        "RULE_V1_NOT_EDITABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette règle est une règle V1 et n'est pas éditable. Si vous souhaitez la modifier, veuillez contacter Meelo."])}
      },
      "SETS": {
        "SETS_RULES_EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour consulter les règles d'un ensemble, merci de sélectionner la ligne de l'ensemble en question dans le tableau de gauche."])},
        "NO_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a aucun ensemble de règles pour votre compagnie. Veuillez contacter Meelo pour en créer."])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "SETS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des ensembles"])},
        "SETS_RULES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des règles de l'ensemble"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous trouverez ici la liste des ensembles de règles créés pour votre compagnie."])},
        "ADD_OR_DELETE_RULE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour toute modification concernant un ajout ou une suppression de règle dans un ensemble, merci de contacter Meelo."])}
      }
    },
    "MY_COMPANY": {
      "USEFUL_CONTACTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts utiles"])},
      "KITS_AND_MODULES_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kits et modules disponibles"])},
      "ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
      "SUPPORT_MEELO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Meelo"])}
    }
  },
  "RESEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
  "RESULTS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats trouvés"])},
  "NUMBER_OF_ITEMS_PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d’éléments par page"])},
  "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif"])},
  "DATA_CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactabilité des données"])},
  "AND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
  "UNREACHABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non contactable"])},
  "LOCALE": {
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
    "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Néérlandais"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugais"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgique"])}
  },
  "ADVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avis"])},
  "E_REPUTATION": {
    "AVERAGE_GRADE_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note moyenne non trouvée"])},
    "LIST_TEN_LAST_REVIEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des 10 derniers avis"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Meelo E-Reputation"])},
    "NEGATIVE_REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis à tendance négative"])},
    "REVIEW_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire de l'avis"])}
  },
  "NO_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commentaire"])},
  "PRINT": {
    "FOLDER_INFO": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations du dossier"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
      "FOLDER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etat du dossier"])},
      "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyste"])},
      "UNIQ_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant unique"])}
    },
    "APPLICANT_OB": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandeur"])},
      "OB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de l'OpenBanking"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "BIRTH_PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse mail"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone mobile"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse postale"])},
      "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
      "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titulaire"])},
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du compte"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte principal"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaire mensuel moyen"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyer mensuel moyen"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part des dépenses sur les revenus"])},
      "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance mensuelle"])},
      "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solde actuel"])},
      "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervision bancaire"])},
      "TOP_DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détection débits différés"])},
      "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de transactions"])}
    },
    "DOCUMENT_ANALYSIS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des documents"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conformité"])},
      "DOCUMENT_CONTROL": {
        "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce d'identité"])},
        "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permis de conduire"])},
        "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport"])},
        "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de séjour"])},
        "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Compagnie ou de situation"])},
        "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS Avis de situation"])},
        "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrait de Kbis"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticité - Analyse de la non-altération numérique"])},
        "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale"])},
        "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale simplifiée"])},
        "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale complexe"])},
        "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis d'imposition - Revenus ou fonciers"])},
        "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôt sur le revenu"])},
        "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impôt foncier"])},
        "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
        "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulletin de paie"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])}
      }
    },
    "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du score"])},
    "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse d'IBAN"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lutte contre le blanchiment et le terrorisme"])},
    "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse détaillée de l'OpenBanking"])},
    "ANALYSIS_B2B": {
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur l'entreprise"])},
      "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations juridiques"])},
      "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations client"])},
      "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison sociale"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de registre"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectif"])},
      "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
      "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de TVA"])},
      "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code NAF ou APE"])},
      "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forme juridique"])},
      "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital social"])},
      "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
      "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine d'activité"])},
      "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de clôture"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliation"])},
      "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarant"])},
      "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site web"])},
      "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergeur"])},
      "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actionnaires et bénéficiaires connus"])},
      "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "NATIONALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
      "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d'identification"])},
      "WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning List Meelo"])},
      "BUSINESS_REPRESENTS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirigeants et représentants connus"])},
      "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du score"])},
      "COMPANY_SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score confiance entreprise"])},
      "COMPANY_FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances entreprises"])},
      "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents juridiques"])},
      "ANNUAL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes annuels"])},
      "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissements"])},
      "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évolution entreprise"])},
      "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores financiers"])},
      "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-réputation"])},
      "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse détaillée de l'OpenBanking"])},
      "ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse IBAN"])},
      "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lutte contre le blanchiment et le terrorisme"])}
    },
    "COVER_PAGE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport Meelo des contrôles réalisés"])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier réalisé par"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier créé le"])},
      "DOC_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse Entreprise"])},
      "DOC_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse Particulier"])},
      "DOC_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse Open-Banking"])},
      "DOC_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse de document"])},
      "DOC_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthèse de document"])}
    }
  },
  "ENRICHMENT": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de lancer une recherche de coordonnées, merci de coller ci-dessous l'url du profil Linkedin de votre contact."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrichissement des données"])},
    "NOPROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun profil Linkedin trouvé."])},
    "NOCORDINATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune coordonnée trouvée."])},
    "TITLE_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées trouvées :"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email(s):"])},
    "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail(s) professionnel(s) :"])},
    "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail(s) personnel(s) :"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone(s):"])},
    "NO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun téléphone trouvé"])},
    "NO_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun email trouvé"])},
    "MATCH": {
      "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match avec l'e-mail personnel saisi"])},
      "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match avec l'e-mail professionnel saisi"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match avec le téléphone saisi"])}
    }
  },
  "DECLARATION": {
    "FRAUD": {
      "BUTTON_SIDEBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déclarer une fraude"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration de fraude"])},
      "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de compléter le formulaire ci-dessous pour les dossiers comportant une usurpation d’identité ou une escroquerie avérée."])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Date de réception de la demande frauduleuse"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Type de dossier"])},
      "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Adresse mail declarée"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Numéro de téléphone déclaré"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant estimé de la fraude"])},
      "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL du dossier"])},
      "AVOID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fraude a-t-elle  été évitée ?"])},
      "TOOLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A l'aide de quel(s) outil(s)"])},
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quels elements vous font dire qu'il s'agit d'une fraude ? "])},
      "CONDITION_FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" * J'accepte les"])},
      "CONDITION_SECOND_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions générales d'utilisation"])},
      "CONDITION_THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Meelo relative à la declaration manuelle de fraude"])},
      "PROFESIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnel"])},
      "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulier"])}
    }
  },
  "RECOMMENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information facultative, mais fortement recommandée lors d'une déclaration "])},
  "THANKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])},
  "CONFIRM_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre déclaration a bien été envoyée"])},
  "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOUVEAUTÉ"])},
  "MODULE": {
    "ALTARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Altares"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "BUSINESS_OWNERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaires effectifs"])},
    "CHECK_IBAN_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification d'IBAN B2B"])},
    "COMPANY_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes sur une entreprise"])},
    "COMPANY_ANNUAL_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes annuels sur une entreprise"])},
    "COMPANY_BODACC_ANNOUNCEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "COMPANY_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissements sur une entreprise"])},
    "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évolution d'une entreprise"])},
    "COMPANY_FINANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finances d'une entreprise"])},
    "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur une entreprise"])},
    "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents juridiques sur une entreprise"])},
    "COMPANY_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Confiance Entreprise"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Credit Safe"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation"])},
    "OB_ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes Open Banking"])},
    "LITIGATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation juridique - Contentieux"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jugée le "])},
      "DEMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande: "])},
      "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localisation: "])},
      "MAIN_HEARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fond"])},
      "SUMMARY_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référé"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])}
    },
    "FINANCIAL_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section analyse risque financier"])}
  },
  "CARTOGRAPHY": {
    "COMPANY_COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procédure collective"])},
    "BUSINESS_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison sociale"])},
    "CARTOGRAPHY_OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartophraphie de"])},
    "STUDIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise analysée"])},
    "ACTIVE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise en activité"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise en cours de procédure collective"])},
    "COMPANY_CARTOGRAPHY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartographie entreprise"])},
    "DEREGISTERED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise radiée"])},
    "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne physique"])},
    "COMPANY_ACTIVITIES_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d’activité entreprise"])},
    "COMPANY_ACTIVITIES_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d’activité entreprise"])},
    "RECENTLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["récemment"])},
    "UNVERIFIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise non verifiée"])},
    "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrandir"])}
  },
  "MENU": {
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "MY_CONSUPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma consommation"])},
    "PORTAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information Portail"])},
    "LOG_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me connecter en tant que"])},
    "BACK_OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-office"])},
    "LOGOUT_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me déconnecter du compte client"])},
    "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])}
  },
  "CONNECT_AS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me connecter en tant que "])},
    "PARAGRAPH": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de vous connecter en tant que"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de la companie"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous verrez donc le portail en fonction des permissions accordées à cet utilisateur."])},
      "FOURTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes vos actions seront tracées sous le nom du client et du votre."])},
      "FIFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de"])},
      "SIXTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rester prudent"])},
      "SEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lors de votre utilisation du portail en tant qu'un client, et de "])},
      "EIGHTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respecter la confidentialité"])},
      "NINTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des données de nos clients."])},
      "TENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N'oubliez pas de vous "])},
      "ELEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["déconnecter du compte client"])},
      "TWELFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["une fois vos actions realisées."])}
    },
    "TOAST": {
      "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes plus connecté(e) en tant que"])},
      "LOG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous etes bien connecté(e) en tant que"])}
    },
    "BADGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connecté(e) en tant que"])},
    "MODAL": {
      "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compagnie"])},
      "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])}
    }
  },
  "PORTAL_INFORMATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le portail MEELO"])},
    "SUBTITLE": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le portail Meelo :"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une recommandation en un coup d'oeil"])}
    },
    "DESCRIPTION": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les produits Meelo à portée de main pour prendre votre décision en seulement quelques minutes."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre portail d’aide à la décision permet d’utiliser les produits Meelo nécessaires à la bonne vérification du client qui entre en relation avec vous."])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Fraude, Vérification d’identité, Open Banking, Vérification documentaire… les produits Meelo à portée de main avec une intégration technique minimale."])}
    },
    "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version de votre portail"])},
    "ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement"])},
    "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
    "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développement"])},
    "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staging"])},
    "GDPR": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RGPD"])},
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous accordons une grande importance à la protection de vos données personnelles. Conformément au Règlement général sur la protection des données (RGPD), nous collectons uniquement les informations nécessaires, obtenons votre consentement explicite, utilisons vos données et celles de vos clients de manière légitime, assurons leur sécurité, limitons leur conservation et respectons vos droits."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, vous pouvez consulter notre"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ou contacter notre DPO, à "])}
    },
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["page dédiée"])}
  },
  "MAINTENANCE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les services suivants peuvent rencontrer des problèmes : "])},
    "FINISHED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pensez à rafraîchir votre portail pour une utilisation optimale."])},
    "ONGOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance en cours"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance terminée"])},
    "SERVICE": {
      "AML_CFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de Lutte contre le Blanchissement d'Argent et le Financement du Terrorisme"])},
      "CHECKOUT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de Signature Electronique"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'upload de documents"])},
      "DOCUMENT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de Contrôle Documentaires"])},
      "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de Documentation Externes"])},
      "IAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'Authentification"])},
      "OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'OpenBanking"])},
      "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portail Meelo"])},
      "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moteur de règles"])},
      "SCORING_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de Scoring"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de Gestion de Tâches Internes"])},
      "TAX_NOTICE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'analyse d'avis d'imposition"])},
      "TAX_REPORT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'analyse de liasses fiscales"])},
      "TRANSLATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service interne de Traduction"])},
      "SHORTENER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service interne de Raccourcissement d'URL"])},
      "CHECK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'Enrichissement Entreprises"])},
      "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Interne d'upload documentaires"])},
      "TRACKETIO_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service permettant l'analyse des éléments remontés par le tracking Meelo"])},
      "STORAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service interne de stockage documentaire"])},
      "ALERTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'alerting"])},
      "TRACKETIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service permettant le tracking des utilisateurs sur le navigateur"])},
      "OPEN_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'OpenRevenue"])},
      "ENRICHMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service d'enrichissement de données"])},
      "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service de recherche et matching d'images"])}
    }
  },
  "FOLDER": {
    "WORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dossier"])},
    "STATE": {
      "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepté"])},
      "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusé"])},
      "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
      "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])}
    },
    "DELETION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du dossier"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de supprimer le"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et les données qui le constitue"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous certain de vouloir supprimer ce dossier ?"])}
      }
    },
    "EDITION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour de l'état du dossier"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pourrez plus continuer la saisie sur ce dossier"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous certain de vouloir modifier l'état du dossier ?"])}
      },
      "STATE": {
        "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mettre en cours"])},
        "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepter"])},
        "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refuser"])},
        "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mettre en attente"])},
        "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["annuler"])},
        "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["supprimer"])}
      }
    },
    "UPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status du dossier mis à jour"])}
  },
  "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nº"])},
  "NOTIFICATIONS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune notification."])},
    "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveauté "])},
    "READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lues "])},
    "MARK_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOUT MARQUER COMME LU"])},
    "DELETE_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOUT SUPPRIMER"])},
    "DATE": {
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUJOURD'HUI"])},
      "YESTERDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIER"])},
      "OLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANCIENNES"])}
    },
    "TIMESTAMP": {
      "NOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A l'instant"])},
      "PREFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["il y a"])},
      "SUFFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "MINUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute"])},
      "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
      "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heure"])},
      "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["heures"])}
    },
    "ACTIONS": {
      "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
    },
    "SERVICES": {
      "OPEN_BANKING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking - Analyse terminée"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking - Echec de l'analyse"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'analyse OpenBanking du dossier "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminée."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminée. Cliquez sur la notification pour consulter le résultat."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’analyse OpenBanking a rencontré un problème. Cliquez sur la notification pour consulter le dossier."])}
      },
      "LIVE_CHECK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de doutes - Analyse terminée"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levée de doutes - Echec de l'analyse"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La levée de doutes du dossier "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminée."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminée. Cliquez sur la notification pour consulter le résultat."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La levée de doutes a rencontré un problème. Cliquez sur la notification pour consulter le dossier."])}
      },
      "BATCH_CHECK_COMPANY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse entreprise - Batch terminé"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse entreprise - Echec du batch"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le batch analyse entreprise "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminé."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminé."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le batch analyse entreprise a rencontré un problème."])}
      },
      "BATCH_CHECK_IBAN": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse iban - Batch terminé"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse iban - Echec du batch"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le batch analyse iban "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminé."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminé."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le batch analyse iban a rencontré un problème."])}
      },
      "BATCH_SCORING_PROXY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Batch terminé"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Echec du batch"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le batch analyse scoring "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminé."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est terminé."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le batch analyse scoring a rencontré un problème."])}
      }
    }
  },
  "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État"])},
  "OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "THE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le"])},
  "SCORE_CREDIT_SAFE": {
    "RISK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque financier"])},
    "PAYMENT_TREND_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendance de paiement"])},
    "OTHER_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres informations complémentaires"])},
    "PRESENCEOFJUDGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de jugement"])},
    "PRESENCEOFPRIVILEGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de privilège"])},
    "PRESENCEOFSANCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présence de la sanction DGCCRF"])},
    "HEADBAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour en connaître plus sur la santé financière de cette entreprise, veuillez activer votre abonnement CreditSafe"])},
    "RISK": {
      "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque financier faible"])},
      "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque financier modéré"])},
      "HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risque financier élevé"])},
      "NOT_SCORED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non vérifiable"])}
    },
    "PAYMENT_TREND": {
      "WORSENING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendance de paiement se détériorant"])},
      "IMPROVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendance de paiement s’améliorant"])},
      "STABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendance de paiement stable"])},
      "NOT_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendance de paiement non disponible"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tendance de paiement non vérifiable"])}
    }
  },
  "MALICIOUS_PAYLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données saisies invalides"])},
  "RESET_API_KEY": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre clé d'API"])},
    "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez consulter votre nouvelle clé d'API qu'une seule fois"])},
    "CANNOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez plus visualiser votre clé API, merci de vous rapprocher de votre référent ou de contacter le support Meelo"])},
    "COPY_YOUR_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier votre clé"])}
  },
  "DQE_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching de la ligne téléphonique"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, la recherche n'a pas pu aboutir."])},
    "NO_SEARCH_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recherche n'est pas disponible pour cet opérateur téléphonique."])},
    "MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching"])},
    "KEY": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse postale"])}
    },
    "LAUNCH_MATCHING_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer la recherche de matching"])},
    "NO_GLOBAL_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le contrôle a été fait mais ne peut pas aboutir."])}
  }
}