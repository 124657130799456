export default {
  "ACCOUNT_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "GENERAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Information"])},
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "PHONE_NUMBER_NOT_SPECIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not specified"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-Factor Authentication Setup"])},
      "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required by Company"])},
      "CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit My Information"])},
      "MODE": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication Mode"])},
        "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
        "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled"])}
      }
    },
    "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Information"])}
  },
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
    "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credentials do not match any account"])},
    "ATTEMPTS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consecutive incorrect attempts will lock your account."])},
    "LOCKED_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been blocked."])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I forgot my password"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-Factor Authentication"])},
      "ALERT_MESSAGE_CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation will override your previous two-factor authentication method. Are you sure you want to continue?"])},
      "ALERT_MESSAGE_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This operation will activate two-factor authentication."])},
      "ALERT_MESSAGE_DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will disable two-factor authentication."])},
      "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable Two-Factor Authentication"])},
      "DISABLE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can disable two-factor authentication. However, we recommend keeping this feature enabled to enhance the security of your account."])},
      "COMPANY_2FA_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please tell us which authentication method you would like to use."])},
      "EMAIL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A verification code will be sent to your email address"])},
      "SMS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A verification code will be sent to you via SMS. Please provide your phone number:"])},
      "TITLE_MODAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Two-Factor Authentication Mode"])},
      "MESSAGE_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have received a"])},
      "FINALISATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To complete two-factor authentication, please enter the code that has been sent to you"])}
    }
  },
  "RESET_PASSWORD_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "INIT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialize Password"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "CONFIRM_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send password reset email"])},
    "SUCCESS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A password reset email has been sent to you."])},
    "EMAIL_NOT_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email could not be sent."])},
    "INVALID_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character."])},
    "PASSWORD_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords do not match."])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been successfully reset."])},
    "PASSWORD_RESET_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while resetting your password."])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By continuing, you accept the"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of Meelo"])}
  },
  "IDENTITY": {
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastname"])},
    "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "PLACE_OF_BIRTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of birth"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone"])},
    "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "USAGE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common name"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
  },
  "ADDRESS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "TITLE_POSTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal address"])},
    "NUMBER_AND_STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street number and name"])},
    "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
    "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])}
  },
  "COMPANY": {
    "NO_REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No known leaders and representatives"])},
    "NO_BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No known shareholders and owners"])},
    "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidation"])},
    "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiation"])},
    "SIREN_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
    "ORIGIN": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])}
    },
    "REGISTRATION_NUMBER": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company identification number or SIREN number"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["National registration number or VAT number"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Identification Code"])}
    },
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
    "SIRET_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
    "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "MAIN_ACTIVITY_DECLARED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main activity declared"])},
    "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code NAF ou APE"])},
    "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company information"])},
    "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of creation"])},
    "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate name"])},
    "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal form"])},
    "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share capital"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity of the company"])},
    "BUSINESS_OWNERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of beneficial owners"])},
    "BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Known beneficial owners"])},
    "DIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Beneficiary"])},
    "INDIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirect beneficiary via legal entity"])},
    "IN_POSITION_SINCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In position since"])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mothballed"])},
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceedings in progress"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collective procedures"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-reputation"])},
    "GLOBAL_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overall rating"])},
    "ADVICE_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of reviews"])},
    "WORDING_CLOUD_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recurrence of words in reviews"])},
    "ADJECTIVES_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive opinions / Negative opinions in reviews"])},
    "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of establishments"])},
    "ESTABLISHMENTS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of establishments"])},
    "REPRESENTATIVES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of representatives"])},
    "REPRESENTATIVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representatives"])},
    "REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directors and representatives known"])},
    "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Known shareholders and beneficiaries"])},
    "RECOMMENDED_OUTSTANDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended Outstanding Amount"])},
    "HEADQUARTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headquarter"])},
    "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "TOTAL_SHARE_HOLDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total holding of shares"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of origin of the company"])},
    "TYPE_PERSON": {
      "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural person"])},
      "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal person"])}
    },
    "FUNCTIONS": {
      "PRESIDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["President"])},
      "CHAIRMAN_OF_THE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman of the Board"])},
      "CHAIRMAN_AND_CHIEF_EXECUTIVE_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman and Chief Executive Officer"])},
      "MANAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director"])},
      "NON_ASSOCIATE_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-Associate Manager"])},
      "CO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-Manager"])},
      "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director"])},
      "GENERAL_MANAGER_NOT_A_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Manager (Non-Director)"])},
      "SOLE_MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sole Managing Director"])},
      "CHIEF_OPERATING_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Operating Officer"])},
      "VICE_CHAIRMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vice Chairman"])},
      "DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Director"])},
      "DEPUTY_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deputy Administrator"])},
      "CHAIRMAN_OF_THE_EXECUTIVE_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman of the Executive Directors"])},
      "CHAIRMAN_OF_THE_SUPERVISORY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman of the Supervisory Board"])},
      "MANAGING_PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Partner"])},
      "CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller"])},
      "STATUTORY_AUDITOR_TITULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statutory Auditor - Titular"])},
      "STATUTORY_AUDITOR_DEPUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statutory Auditor - Deputy"])},
      "STATUTORY_AUDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statutory Auditor"])},
      "LIQUIDATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidator"])},
      "PROVISIONAL_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisional Administrator"])},
      "REPRESENTATIVE_IN_FRANCE_OF_A_FOREIGN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative in France of a Foreign Company"])},
      "MEMBER_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member of the Board of Directors"])},
      "CHAIRMAN_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chairman of the Board of Directors"])}
    },
    "TYPE": {
      "SOCIETY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "COLLECTIVE_INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collective Investment"])},
      "GIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economic Interest Group"])}
    },
    "OF_SHARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of shares"])},
    "OF_VOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of votes"])},
    "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Information"])},
    "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workforce"])},
    "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Registration Number"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closure Date"])},
    "ACTIVITY_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity status"])},
    "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "UNVERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-resident"])},
    "RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendation"])},
    "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field of Activity"])},
    "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishments"])},
    "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headquarters"])},
    "IN_OPERATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Operation"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closed"])},
    "FINANCES": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Finances"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No financial data available"])},
      "RATIOS": {
        "grossMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Margin (€)"])},
        "netMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Margin (€)"])},
        "valueAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Added (€)"])},
        "ebitda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBITDA (€)"])},
        "ebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBIT (€)"])},
        "profitBeforeTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit Before Tax (€)"])},
        "extraordinaryResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraordinary Result (€)"])},
        "netIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Income (€)"])},
        "shortTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-Term Debt (€)"])},
        "longMidTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long and Mid-Term Debt (€)"])},
        "turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turnover (€)"])},
        "balanceSheetTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance Sheet Total (€)"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital and Reserves (€)"])},
        "workingCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Capital (€)"])},
        "workingCapitalRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Capital Requirement (€)"])},
        "financialLiquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Treasury (€)"])},
        "intangibleAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intangible Assets (€)"])},
        "tangibleFixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tangible Fixed Assets (€)"])},
        "participatingInterestsAndRelatedDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participating Interests and Related Debts Receivable (€)"])},
        "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock (€)"])},
        "othersDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Debts Receivable (€)"])},
        "tradeDebtorsAndRelatedAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade Debtors and Related Accounts (€)"])},
        "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treasury (€)"])},
        "chargesCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Expenses (€)"])},
        "appropriationsToDepreciationAndProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depreciation and Provisions (€)"])},
        "totalExtraordinaryIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Extraordinary Income (€)"])},
        "totalExtraordinaryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Extraordinary Charges (€)"])},
        "corporateTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Tax (€)"])},
        "operatingLiabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Liabilities (€)"])},
        "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital (€)"])},
        "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve (€)"])},
        "profitOrLossForTheFinancialYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit or Loss for the Financial Year (€)"])},
        "investmentGrantsTaxRegulatedProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other (€)"])},
        "loansAndDebtsPayableToCreditInstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long-Term Bank Loans (€)"])},
        "loansAndSundryFinancialDebtsPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loans and Miscellaneous Financial Debts (€)"])},
        "changeInStocksIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Income (€)"])},
        "purchasesOfGoodsForResale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase of Goods for Resale (€)"])},
        "changeInStocksCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change in Stock Costs (€)"])},
        "otherExternalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Purchases and External Charges (€)"])},
        "taxesLeviesAndSimilarPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes, Levies, and Similar Payments (€)"])},
        "personnelCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaries and Wages (€)"])},
        "otherCurrentOperatingCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Operating Charges (€)"])},
        "financialIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Income (€)"])},
        "financialCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Charges (€)"])},
        "fpTb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equity to Total Assets Ratio (%)"])},
        "supplierDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Deadlines (days)"])},
        "customerDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Deadlines (days)"])},
        "caf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Flow from Operations (€)"])},
        "rnCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Profit Margin on Sales (%)"])},
        "etp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-Time Equivalent (FTE)"])},
        "dividends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividends Paid to Shareholders (€)"])},
        "soldeFinancier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Balance (€)"])},
        "salesGrowthRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales Growth Rate (%)"])},
        "grossMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gross Margin Rate (%)"])},
        "ebitdaMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBITDA Margin Rate (%)"])},
        "operatingMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Margin Rate (%)"])}
      },
      "BLOCKS": {
        "intermediateOperationTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermediate Operation Totals"])},
        "debts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debts"])},
        "balanceSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance Sheet"])},
        "fixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed Assets"])},
        "currentAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Assets"])},
        "profitAndLossAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit and Loss Account"])},
        "balancePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive Balance"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital and Reserves"])},
        "operatingIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Income"])},
        "operatingCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Costs"])},
        "operatingResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operating Result"])},
        "ratios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Ratios"])},
        "increase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growth"])}
      }
    },
    "LEGAL_DOCUMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Documents"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No legal documents available"])},
      "NO_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To view the document, please download it."])}
    },
    "BODACC_ANNOUNCEMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Evolution"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "NATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature"])},
      "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
    },
    "ANNUAL_ACCOUNTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Accounts"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Annual Accounts Available"])},
      "COMPANY_FINANCIAL_STATEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Financial Statements"])}
    },
    "CHECKS": {
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than one financial report filed on the same date"])},
      "LESS_THAN_X_YEARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company less than 3 years old"])},
      "MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management change less than 3 years"])},
      "SHARE_CAPITAL_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change in share capital within the last 3 years"])},
      "HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headquarters transfer within the last 3 years"])},
      "ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change in activity within the last 3 years"])},
      "MULTI_MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 2 management changes in the last 10 years"])},
      "HAS_CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessation of activity"])},
      "HAS_ALL_ESTABLISHMENTS_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All establishments are closed"])},
      "HAS_ACTIVITY_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than two changes in activity at least in the last 10 years"])},
      "HAS_HEADQUARTER_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than two headquarters relocations in the last 10 years"])},
      "LOCATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Location"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciled Business"])},
      "WEBSITE_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website Domain Registration Date"])},
      "EMAIL_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Domain Registration Date"])},
      "EMAIL_DOMAIN_EQUALS_WEBSITE_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match between Email and Website Domains"])},
      "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiation"])},
      "COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing Proceedings"])},
      "OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Company in Collective Proceedings"])},
      "OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Company Radiated"])},
      "ACTIVITIES_CODE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risky Activity Sector"])},
      "HEADQUARTER_DISTANCE_TOO_HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Significant distance between the address of the registered office and the residence of the manager"])},
      "LEGAL_SITUATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of litigation"])},
      "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive Period Exceeding 3 Years"])},
      "MULTI_HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 2 Headquarters Transfers in the Last 10 Years"])},
      "MULTI_ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 2 Activity Changes in the Last 10 Years"])},
      "CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cessation of Activity"])},
      "ALL_ESTABLISHMENTS_ARE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Establishments Are Closed"])},
      "CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Contactability"])},
      "HEADQUARTER_ADDRESS_IN_WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud has been detected at this address"])},
      "MULTI_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than one registration since creation"])},
      "MORE_THAN_ONE_ESTABLISHMENT_DOMICILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one establishment is domiciled"])}
    },
    "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliation"])},
    "BRANCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch"])},
    "CHECK_EMPLOYER": {
      "NO_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information could not be verified"])},
      "FALSE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Company is not delisted"])},
      "TRUE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Company is delisted"])},
      "COLLECTIVE_PROCEEDING": {
        "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pending collective proceedings detected"])},
        "MORE_THAN_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more pending collective proceedings detected"])},
        "RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more current collective proceedings detected, including at least one recent one (< 3 years)"])}
      }
    }
  },
  "ERRORS": {
    "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! It seems there's a problem. Our teams are already working on resolving it. Please try again later."])},
    "NOT_FOUND_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered registration number does not exist."])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No information retrieved"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our tax return processing tool does not support documents prior to 2018."])},
    "TAX_REPORT_TOO_OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax return is too old"])},
    "DOCUMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during document processing"])},
    "COMPANY_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered Identification number is not associated with any active company."])},
    "ENRICHMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No information found."])},
    "ERROR_OCCURRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred, please try again later"])},
    "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found, please start a new analysis"])},
    "CALL_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call finished, please note that some data may be inaccessible. For a complete file, please start a new analysis"])},
    "SIREN_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis completed, no data found with the provided registration number"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data retrieved"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We encountered an error with the phone number entered. Please check the information entered"])}
  },
  "BUTTONS": {
    "VALIDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
    "VALIDATE_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate Information"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "VERIFY_IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Verification"])},
    "START_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Open Banking"])},
    "JOURNEY_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the Open Banking journey in the browser"])},
    "FINISH_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Analysis"])},
    "NEW_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start a New Analysis"])},
    "EXPORT_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Summary"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "DETAILS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis Details"])},
    "VERIFY_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Address"])},
    "SEND_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send SMS"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Email"])},
    "BACK_HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Home"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML/CFT"])},
    "VALIDATE_AND_SEND_SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate and Send SMS"])},
    "VALIDATE_AND_SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate and Send Email"])},
    "IFRAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe"])},
    "RUN_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Run Analysis"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Analysis"])},
    "FRAUD_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud Scores"])},
    "SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])},
    "DEACTIVATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "ENGLISH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English Version"])},
    "FRENCH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French Version"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "UPLOAD_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload here"])},
    "MY_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My company"])},
    "MY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My users"])},
    "CONSUMPTION_MONITORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption monitoring"])},
    "EXTRACT_MEMORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extract memory"])},
    "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules engine"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "LINK_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link via SMS"])},
    "NEW_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New request"])},
    "RESTART_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart the request"])},
    "LINK_BROWSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser link"])},
    "LINK_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link via Email"])},
    "CREATE_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a user"])},
    "UNBLOCK_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unblock a user"])},
    "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "CONFIRM_AND_CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm and continue"])},
    "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "MODIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset"])},
    "ACTIVATE_DEACTIVATE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate / Deactivate account"])},
    "RESETTING_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetting API key"])},
    "DELETE_THE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the account"])},
    "RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
    "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "CONSULT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult the Finovox analysis"])},
    "DEPLOY_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deploy everything"])},
    "REDUCE_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduce all"])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To go to LinkedIn"])},
    "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity score"])},
    "SCORE_COMPANY_FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company fraud score"])},
    "SELECT_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])},
    "MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My team"])},
    "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "COMPLETE_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the upload"])},
    "LIVE_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LiveCheck"])}
  },
  "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
  "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
  "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked"])},
  "MAIL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail-Linked Accounts"])},
  "PHONE_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone-Linked Accounts"])},
  "APPLICANT_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant Results"])},
  "CONTACT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Information"])},
  "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Information"])},
  "VERIFICATION_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Verification"])},
  "VERIFICATION_TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Notice Verification"])},
  "CONFIDENCE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity Fraud Score"])},
  "SMB_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Identity Fraud Score"])},
  "CONFIDENCE_INDEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity Fraud Score"])},
  "DETAILED_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Details"])},
  "CONFIDENCE_EXPLANATIONS": {
    "UNDER_TWENTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided data not related to the individual"])},
    "BETWEEN_TWENTY_AND_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspicious data, identity verification recommended"])},
    "ABOVE_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing and person-linked provided data"])}
  },
  "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Analysis"])},
  "ANALYSIS_RESULT_RETRIEVAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieving analysis results in progress..."])},
  "UPLOAD_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document upload finished, you can close this window."])},
  "LINK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link with the Company"])},
  "IDENTITY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity Consistency"])},
  "COMPANY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistency with the Company"])},
  "OTHER_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Person"])},
  "SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation"])},
  "NOT_FOUND": {
    "NO_BUSINESS_OWNERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No known beneficial owners."])},
    "NO_REPRESENTATIVES_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No known representatives."])},
    "NO_LEADERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No retrieved leaders..."])}
  },
  "OPEN_BANKING": {
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data has been successfully transmitted. You can close this page to view the analysis results."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "RESULT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking Analysis"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed OpenBanking Analysis"])},
    "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "INCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomes"])},
    "OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outcomes"])},
    "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder"])},
    "ACCOUNT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
    "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Salary"])},
    "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Rent"])},
    "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Balance"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ask your client to have their smartphone and banking details ready."])},
    "CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "FINANCIAL_ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Analysis Period"])},
    "ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzed period"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "CATEGORIES": {
      "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Transaction Amount"])},
      "MONTH_MAX_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Maximum Transaction"])},
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Total Monthly Amount"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Salaries"])},
      "MONTH_SALARIES_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Date - Salaries"])},
      "MONTH_INTERESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Interests"])},
      "MONTH_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Transfers"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Rents"])},
      "MONTH_WITHDRAWALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - ATM Withdrawals"])},
      "MONTH_INSURANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Insurance"])},
      "MONTH_UTILITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Utilities"])},
      "MONTH_GROCERIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Grocery Shopping"])},
      "MONTH_FOOD_CONSUMPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Food Consumption"])},
      "MONTH_SHOPPINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Shopping"])},
      "MONTH_HEALTH_WELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Health/Wellness"])},
      "MONTH_TRANSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Transportation"])},
      "MONTH_TELECOMMUNICATION_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Telecommunication Service Subscriptions"])},
      "MONTH_AUDIO_VISUAL_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Amount - Audio-Visual Service Subscriptions"])},
      "MONTH_INCOMES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly average of income"])},
      "MONTH_INCOMES_SALARIES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly average of salaries"])},
      "MONTH_INCOMES_ALLOWANCES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly average of allowances"])},
      "MONTH_INCOMES_PENSIONS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly average of pensions"])},
      "MONTH_INCOMES_AMOUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest Monthly Average"])},
      "MONTH_INCOMES_AMOUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest Monthly Average"])},
      "MONTH_OUTCOMES_RENTS_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Average Rent"])},
      "MONTH_OUTCOMES_CREDITS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Average Credit Amount"])},
      "MONTH_OUTCOMES_CREDITS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Due Date"])},
      "MONTH_OUTCOMES_PENSIONS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Pension Payment"])},
      "RECOMMENDED_DEBIT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Debit Date"])},
      "RECOMMENDED_DEBIT_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimal Debit Amount"])},
      "MONTHLY_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Amount"])},
      "MONTH_OUTCOMES_RENTS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Rent Amount"])},
      "MONTH_OUTCOMES_RENTS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Rent Amount"])},
      "MONTH_OUTCOMES_UTILITIES_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Utility Amount"])},
      "MONTH_OUTCOMES_UTILITIES_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Utility Amount"])},
      "MONTH_OUTCOMES_CREDITS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Credit Amount"])},
      "MONTH_OUTCOMES_CREDITS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Credit Amount"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Number of Monthly Debits"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Number of Monthly Debits"])},
      "BANK_CARD_AND_DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Card and Debits"])},
      "DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debits"])},
      "BANK_CARD_PRESENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked Bank Card(s)"])},
      "BANK_CARD_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity of Cards Used for Outgoing Transactions"])},
      "BANK_CARD_NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card Numbers Identified for Outgoing Transactions"])},
      "MONTH_NEGATIVE_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earliest Date"])},
      "MONTH_NEGATIVE_BALANCE_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Date"])},
      "MAX_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Date in the Month When Balance is Maximal"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage of Expenses to Income"])},
      "MONTH_NEGATIVE_BALANCE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Date of Going Negative in the Account"])},
      "AVERAGE_MONTHLY_ENTRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly entries"])},
      "TOTAL_FOR_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total for the period"])},
      "AVERAGE_MONTHLY_TAX_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly tax revenue"])},
      "AVERAGE_MONTHLY_EXITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly exits"])},
      "ANNUAL_NET_INCOME_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Net Income Amount"])},
      "ANNUAL_REVENUE_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Revenue Amount"])},
      "MONTHLY_TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly tax amount"])},
      "AVERAGE_MONTHLY_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly payroll"])},
      "OTHER_ACCOUNTS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Accounts"])},
        "MONTH_INCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly savings income"])},
        "MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly savings outcome"])},
        "MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly incoming transfers"])},
        "MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly outgoing transfers"])}
      }
    },
    "DEBT_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt Ratio"])},
    "MONTH_OUTCOMES_CREDITS_COUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Ongoing Credits"])},
    "REMAINS_TO_LIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining to Live"])},
    "START_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date of Financial Analysis Period"])},
    "END_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date of Financial Analysis Period"])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Balance"])},
    "TOP_JOINT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Joint Account"])},
    "TOP_RECOMMENDED_DATE_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Recommended Debit Date"])},
    "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking Supervision"])},
    "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Transactions"])},
    "MOVEMENT_SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Movement Summary"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "ACCOUNT_STATEMENT": {
      "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Account Statement"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCOUNT(S) STATEMENT(S)"])},
      "ACCOUNT": {
        "CHECKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHECKING ACCOUNT"])},
        "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVINGS ACCOUNT"])}
      },
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Transaction"])},
      "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found, please start a new search"])},
      "PRIVACY_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security and data protection reasons, the OpenBanking account statement is no longer be available after a certain period of time."])},
      "CATEGORY": {
        "INCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising Payment"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowance"])},
          "ALLOWANCE": {
            "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Allowance"])},
            "UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unemployment Allowance"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Allowance"])}
          },
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiovisual Subscription"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile"])},
          "AUTOMOBILE": {
            "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile Maintenance"])},
            "PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile Parking"])}
          },
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Transfer"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking Fee"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Collection"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
          "CREDIT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer Credit"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing Credit"])}
          },
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptocurrency"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture Event"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Purchase"])},
          "DEPOSIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation and Charity"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
          "EDUCATION": {
            "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving Education"])},
            "SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Education"])}
          },
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine Fee"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness Subscription"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food Consumption"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudulent Transfer"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambling"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grocery"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health and Wellness"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indemnity"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Transfer"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervention Fee"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Fee"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet Care"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reading Subscription"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimbursement"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
          "REJECT": {
            "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Credit"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Other"])}
          },
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])},
          "RENT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer Rent"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing Rent"])}
          },
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
          "SALARY": {
            "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Salary"])},
            "TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Salary"])}
          },
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Service Subscription"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seizure Fee"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Subscription"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Market"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicate"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telecom Subscription"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utility"])},
          "UTILITY": {
            "ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Utility"])},
            "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Utility"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Utility"])}
          },
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Game"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
        },
        "OUTCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising Payment"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowance"])},
          "ALLOWANCE": {
            "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Allowance"])},
            "UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unemployment Allowance"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Allowance"])}
          },
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiovisual Subscription"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile"])},
          "AUTOMOBILE": {
            "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile Maintenance"])},
            "PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automobile Parking"])}
          },
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Transfer"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking Fee"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash Collection"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit"])},
          "CREDIT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer Credit"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing Credit"])}
          },
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cryptocurrency"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture Event"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Purchase"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donation and Charity"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
          "EDUCATION": {
            "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving Education"])},
            "SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School Education"])}
          },
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine Fee"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitness Subscription"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food Consumption"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudulent Transfer"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambling"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grocery"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health and Wellness"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indemnity"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Transfer"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervention Fee"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Fee"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leisure"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet Care"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reading Subscription"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reimbursement"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject"])},
          "REJECT": {
            "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Credit"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject Other"])}
          },
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])},
          "RENT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumer Rent"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing Rent"])}
          },
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary"])},
          "SALARY": {
            "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Salary"])},
            "TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporary Salary"])}
          },
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security Service Subscription"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seizure Fee"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Subscription"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock Market"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicate"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telecom Subscription"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unpaid"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utility"])},
          "UTILITY": {
            "ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Utility"])},
            "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water Utility"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Utility"])}
          },
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Game"])},
          "WITHDRAWAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
        }
      }
    },
    "RECOMMENDATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations"])},
    "APPROVALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggregations"])},
    "INDEBTEDNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indebtedness"])},
    "HABITAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Housing"])},
    "EXISTING_CREDIT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing Credit Analyses"])},
    "ALERTS": {
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "CAUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cause"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No alerts have been raised"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])}
    },
    "SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water"])},
    "GAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
    "ELECTRICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electricity"])},
    "BANK_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Cards"])},
    "DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deferred Debit Detection"])},
    "GLOBAL_TRANSACTION_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Number of Transactions"])},
    "MONTH_NEGATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Going Negative in the Period"])},
    "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Account"])},
    "NO_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No income transactions have been detected."])},
    "ALERT": {
      "ABSENCE_OF_INCOME": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence of income"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No income transaction has been detected"])}
      },
      "PRESENCE_OF_REJECTS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of rejects"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction(s) with rejects has been detected"])}
      },
      "PRESENCE_OF_UNPAIDS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of unpaids"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction(s) with unpaids has been detected"])}
      },
      "PRESENCE_OF_PENSIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of pensions"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction(s) with pensions has been detected"])}
      },
      "PRESENCE_OF_GAMBLINGS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of gamblings"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction(s) with gamblings has been detected"])}
      },
      "PRESENCE_OF_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of allowances"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction(s) with allowances has been detected"])}
      },
      "PRESENCE_OF_SEIZURE_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of seizure fees"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction(s) with seizure fees has been detected"])}
      },
      "MONTH_NEGATIVE_BALANCE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month negative balance"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have detected one, or more, days with negative balance"])}
      },
      "BANK_WITH_EXTREME_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank with extreme risk level"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have detected one, or more, account(s) from a bank considered to be extremely risky"])}
      },
      "BANK_WITH_HIGH_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank with high risk level"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have detected one, or more, account(s) from a bank considered to be very risky"])}
      },
      "TRANSACTION_HISTORY_DATE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history date"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than 2 month(s) of transaction history were transmitted for this analysis"])}
      },
      "TRANSACTION_HISTORY_DATE_ONE_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history date"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than 1 month(s) of transaction history were transmitted for this analysis"])}
      },
      "PRESENCE_OF_CASH_COLLECTIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of collection agencies"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction with a collection agency detected"])}
      },
      "PRESENCE_OF_CRYPTOCURRENCIES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of cryptocurrencies"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction(s) with cryptocurrencies has been detected"])}
      },
      "TRANSACTION_HISTORY_COUNT": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history count"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than 10 transaction(s) were transmitted for this analysis"])}
      },
      "PRESENCE_OF_CREDITS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of credits per month"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 3 transaction(s) with credits per month has been detected"])}
      },
      "PRESENCE_OF_INTERVENTION_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of intervention fees"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction(s) with intervention fees has been detected"])}
      },
      "PRESENCE_OF_WITHDRAWALS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of withdrawals per month"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 7 transaction(s) with withdrawals per month has been detected"])}
      },
      "PRESENCE_OF_INTERNATIONALS_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of internationals transfers"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least 1 transaction(s) with internationals transfers has been detected"])}
      },
      "TRANSACTION_HISTORY_FOR_ANALYSIS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction history for analysis."])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough transaction were transmitted for good time analysis."])}
      },
      "ABSENCE_OF_TAXE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absence of taxe"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No taxe transaction has been detected"])}
      },
      "LOSS_OF_SALARY": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loss of salary"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loss of salary has been detected"])}
      }
    },
    "NET_INCOME_AND_ANNUAL_TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net income and annual turnover"])},
    "TAXES_AND_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes & Payroll"])},
    "DAILY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily balance"])},
    "MONTHLY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly balance"])},
    "DAILY_AND_MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily and monthly balance"])},
    "WRONG_ANALYSIS_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, the analysis was performed on a personal account and not a business account"])},
    "WRONG_ANALYSIS_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, the analysis was performed on a business account and not a personal account"])},
    "PROVIDER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during data recovery, please try the analysis again."])}
  },
  "SOCIAL_FACE_MATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Search"])},
    "CATCHED_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrieved Images"])},
    "NO_IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No image found"])}
  },
  "AMLCFT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Money Laundering and Terrorism Fighting"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Freezing"])},
    "PPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politically Exposed Person"])},
    "PPE_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This person is identified as politically exposed"])},
    "ADVERSE_MEDIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media"])},
    "ADVERSE_MEDIA_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are several media articles about the applicant."])},
    "ADVERSE_MEDIA_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Media Type"])},
    "COLUMN_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML/CFT"])},
    "SANCTIONED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanctioned"])},
    "SANCTIONED_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This person has been identified as sanctioned."])},
    "SANCTIONED_EXPLANATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This can involve asset freezing, links to terrorism and/or money laundering."])},
    "CLASS_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heads of State, members of national executive, members of national legislatures, high-ranking military and law enforcement officials, high-ranking officials of national agencies, members of central bank boards, high-ranking judicial officials (national level), Top-level political party leaders"])},
    "CLASS_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members of the boards of international organizations and international sports federations, ambassadors, high commissioners, and other high-level diplomatic posts, members of regional executive, members of regional legislative assemblies, high-ranking judicial officials (regional level)"])},
    "CLASS_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executive management and board members of companies and public organizations"])},
    "CLASS_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayors and members of local authorities (sub-regional level)"])},
    "LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "ADVERSE_MEDIA_DETAILS": {
      "financial-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Crime"])},
      "violent-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violent Crime"])},
      "sexual-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexual Crime"])},
      "terrorism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrorism"])},
      "fraud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud"])},
      "narcotics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narcotics"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property"])},
      "financial-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial AML CFT"])},
      "fraud-linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud-Linked"])},
      "narcotics-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narcotics AML CFT"])},
      "violence-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Violence AML CFT"])},
      "cybercrime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cybercrime"])},
      "general-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General AML CFT"])},
      "regulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulatory"])},
      "financial-difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Difficulty"])},
      "violence-non-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non AML CFT Violence"])},
      "other-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Financial"])},
      "other-serious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Serious"])},
      "other-minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Minor"])}
    },
    "LINKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
    "NO_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This person is neither identified as sanctioned nor identified as politically exposed."])}
  },
  "TOOLTIP": {
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company with an ongoing legal procedure."])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company put into dormancy and reactivated since."])},
    "MOBILE_PHONE_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone verification."])},
    "SOCIAL_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images found on Google related to the provided information."])},
    "EMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email verification."])},
    "AVAILABLES_PICTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images available on social networks related to the applicant's information."])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of establishments owned by the company"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes if there is an ongoing asset freeze."])},
    "SCORE_WEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud scoring on the existence and reliability of the applicant's contact data."])},
    "SCORE_SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk scoring on financial analysis and solvency of the company."])},
    "DEFAULT_PROBABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure of the risk of default of the structure."])},
    "SCORE_LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores updated on"])},
    "SCORE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The score evaluates the financial strength of the company based on its industry and financial statements."])},
    "SCORE_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk scoring on the financial analysis of the bank account."])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly balance over the studied period."])},
    "EMAIL_DISPOSABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disposable Email"])},
    "EMAIL_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail risky"])},
    "MOBILE_PHONE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked phone number"])},
    "EMAIL_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked Email"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue filing the record"])},
    "VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View the record"])},
    "DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate folder"])},
    "DELETE_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete the folder"])},
    "BLOCK_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is impossible to reopen a completed file."])}
  },
  "EMAIL_DISPOSABLE": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The provided email is associated with a disposable domain name."])}
  },
  "DOCUMENT_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Control"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a document type"])},
    "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Card"])},
    "ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Card"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving License"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
    "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence Permit"])},
    "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Company or Situation"])},
    "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Individual"])},
    "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Company"])},
    "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticity - Digital Non-Alteration Analysis"])},
    "AUTHENTICITY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticity Details"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Return"])},
    "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Return - Simplified"])},
    "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Return - Complex"])},
    "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax assessment - Income or property"])},
    "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income Tax"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other type"])},
    "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Tax"])},
    "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Details"])},
    "BLOC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Control"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document analysis failed"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the upload of your document in the left window to view the document analysis."])},
    "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payslip"])},
    "PAYSLIP_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payslip"])},
    "RIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
    "KBIS_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS"])},
    "TAX_NOTICE_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Notice"])},
    "OTHER_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other document"])},
    "NEW_DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse a new document"])},
    "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity Analysis"])},
    "ID_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can initiate document analysis here on identity documents such as national identity cards, passports, residence permits, and driver's licenses."])},
    "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Analysis"])},
    "DOCUMENT_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can initiate document analysis here for a tax return, KBIS, payslip, tax notice, or any other PDF document."])},
    "SELECT_DOCUMENT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one or more types of document"])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])},
    "UNKNOWN_DOC_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document you passed could not be identified as an ID. To check it, remember to upload the front and back of the document or use the document analysis pane."])},
    "NO_DOCUMENT_UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No document uploaded."])},
    "TABLE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance"])},
      "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticity"])},
      "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New identity analysis"])},
      "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New document analysis"])},
      "RESULT": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis Details"])},
        "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
        "WAITING_CONFIRMATION_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis awaiting your confirmation"])},
        "IN_PROGRESS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis in progress"])},
        "LIVE_CHECK": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triggering LiveCheck"])},
          "DESC_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are about to trigger the LiveCheck for the document <", _interpolate(_named("documentName")), ">."])},
          "DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human verification is only available during the first 10 minutes after the document is uploaded."])},
          "DESC_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis processing may take some time, you will be notified once the analysis is complete."])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LiveCheck allows you to request a manual verification of the document by a human operator in less than fifteen minutes. Note that it is only available for 10 minutes after the document upload."])},
          "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LiveCheck in progress"])},
          "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LiveCheck analysis available"])},
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LiveCheck in progress."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The checks below will be updated once the manual analysis is completed. Please wait."])},
          "FAILED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LiveCheck document analysis not available."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A technical error occurred while triggering the LiveCheck document analysis."])},
          "SUCCESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LiveCheck document analysis completed."])},
          "ANALYSIS_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis result"])},
          "ANALYST_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyst message"])}
        },
        "CHECKS": {
          "EMISSION_COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Nationality Consistency"])},
          "EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Type / Emission Date Consistency"])},
          "EXPIRATION_DATE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date Validity"])},
          "VALIDITY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Validity"])},
          "MRZ_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ Control"])},
          "MRZ_CHECKSUMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ Control"])},
          "MRZ_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ Control"])},
          "MRZ_ALIGNEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ Control"])},
          "MRZ_CLASSIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ Control"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person's Identity Consistency / MRZ"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person's Identity Consistency / MRZ"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person's Identity Consistency / MRZ"])},
          "DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Number Consistent with MRZ"])},
          "EXPIRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration Date Consistent with MRZ"])},
          "OCR_EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emission Date Consistent with MRZ"])},
          "PERSONAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Identification Number Consistent with MRZ"])},
          "PHOTO_CONFORMITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Analysis"])},
          "PHOTO_LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Analysis"])},
          "VISUAL_SECURITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document's Visual Security Points Compliance"])},
          "PHOTOCOPY_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photocopy Detection"])},
          "SCREENSHOT_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screenshot Detection"])},
          "OLD_DRIVING_LICENSE_DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Format Driving License Number"])},
          "DRIVING_LICENSE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving License Validity Check"])},
          "DRIVING_LICENSE_DIGITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving License Validity Check"])},
          "AGE_AT_CERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consistent Age at Certification"])},
          "MRZ_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ Control"])},
          "MRZ_IDENTITY_COHERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person's Identity Consistency"])},
          "PICTURE_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo Analysis"])},
          "BARCODE_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcode Found"])},
          "BARCODE_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correctly Encoded Barcode Data"])},
          "BARCODE_SIGNATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valid Barcode Signature"])},
          "TYPE_RECOGNIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document type has been recognized"])},
          "NOT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration date"])},
          "MRZ_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Machine Readable Zone (MRZ) of the document is valid"])},
          "VALIDITY_PERIOD_MODEL_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The validity period model matches"])},
          "OCR_ANALYSIS_MRZ_FIRST_NAMES_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first names in the MRZ match the OCR analysis"])},
          "OCR_ANALYSIS_MRZ_DOCUMENT_NUMBER_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document number in the MRZ matches the OCR analysis"])},
          "DOCUMENT_ASPECT_GENERAL_APPEARANCE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The general appearance of the document matches expectations"])},
          "SECURITY_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security elements present in the document"])},
          "QUALITY_ABOVE_MINIMUM_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document quality is above the minimum threshold"])},
          "NOT_SPECIMEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document is not a specimen"])},
          "FRONT_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The front side of the document has been found"])},
          "OCR_ANALYSIS_MRZ_BIRTH_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The birth name in the MRZ matches the OCR analysis"])},
          "OCR_ANALYSIS_MRZ_BIRTH_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The birth date in the MRZ matches the OCR analysis"])},
          "OCR_ANALYSIS_FAITHFUL_FONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OCR analysis indicates the use of a faithful font in the document"])},
          "DOCUMENT_ASPECT_PICTURE_PRESENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An image is present on the document"])},
          "OCR_ANALYSIS_EXPIRATION_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The expiration date was successfully read through OCR analysis"])},
          "OCR_ANALYSIS_ISSUANCE_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issuance date was successfully read through OCR analysis"])},
          "ISSUANCE_AND_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issuance and expiration dates match"])},
          "NO_TAMPERING_SIGN_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tampering sign was found on the document"])},
          "NO_ANNOTATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No annotation was found on the document"])},
          "BACK_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The back side of the document was found"])},
          "OCR_ANALYSIS_MRZ_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The expiration date in the MRZ matches the OCR analysis"])},
          "OCR_ANALYSIS_MRZ_ISSUANCE_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The issuance date in the MRZ matches the OCR analysis"])},
          "OCR_ANALYSIS_MRZ_LAST_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The last name in the MRZ matches the OCR analysis"])},
          "DOC_2D_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D Doc valid"])},
          "DOC_2D_AND_DOCUMENT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D Doc coherent with document information"])},
          "WORKABLE_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document can be processed for verification"])},
          "WORKABLE_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The element can be processed for verification"])},
          "STATUS_ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted dossier"])},
          "FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraudulent document"])},
          "DOCUMENT_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quality of the document capture does not allow for human review"])},
          "DOCUMENT_INCORRECTLY_FRAMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document is not correctly framed in the image"])},
          "DOCUMENT_SIDE_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only one side of the document is visible (both front and back required)"])},
          "DOCUMENT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document expired"])},
          "DOCUMENT_UNSUPPORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document type not supported"])},
          "DOCUMENT_COUNTRY_UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of document issuance not supported"])},
          "DOCUMENT_UNSUPPORTED_RECEIPT_ACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document type not supported"])},
          "BIOMETRY_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quality of the biometric data does not allow for human review"])},
          "LIVE_CHECK_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical error occurred with the provider"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other reason, see details below"])}
        },
        "KBIS": {
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Date"])},
          "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
          "HEAD_QUARTER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Headquarters Address"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
          "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
          "PUBLICATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS Publication Date"])},
          "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member's Identity"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
          "IDENTIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification"])},
          "MAIN_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Activity"])},
          "MEMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
          "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natural Person(s)"])},
          "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Person(s)"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "BIRTH_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Name"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Names"])},
          "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Form"])},
          "COMPANY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Description"])},
          "ESTABLISHMENT_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishment Description"])}
        },
        "TAX_REPORT": {
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
          "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closing Date of the Fiscal Year"])},
          "OPENING_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opening Date of the Fiscal Year"])},
          "CONFIDENTIALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidential Document"])},
          "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscal Period Duration"])},
          "RATIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Ratios"])},
          "MONTH": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " month"]), _normalize([_interpolate(_named("n")), " months"])])}
        },
        "TAX_NOTICE": {
          "TAX_ADRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax address"])},
          "BANKID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank ID"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account no."])},
          "REMAINING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding balance"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth name"])},
          "NUMBER_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of shares"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family situation"])},
          "CHECKS": {
            "FOUND_2D_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D-DOC Identified"])},
            "NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name Consistency"])},
            "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Establishment Consistency"])},
            "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Income Consistency"])},
            "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Number Consistency"])},
            "REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Consistency"])},
            "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Year Consistency"])},
            "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Parts Consistency"])},
            "NUMBER_OF_REGISTRANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Declarants Consistency"])}
          }
        },
        "AUTHENTICITY": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticity"])},
          "DETAILED_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed Analysis"])},
          "NO_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No alerts were raised during the document analysis."])},
          "BINARY_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document modified since its creation"])},
          "COHERENCE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The document has no coherence in terms of data that we collect."])},
          "AUTOGENERATED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This document originates from software that generates falsified documents"])},
          "METADATA_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata analysis reveals a modification of the document"])},
          "PIXEL_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A portion of the image has been altered"])},
          "SOFTWARE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latest software used is a photo editing software (such as Photoshop), which can be used to resize (to make it authentic) or modify the image."])},
          "REPORT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed Finovox Report (PDF)"])},
          "ANALYSIS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticity analyses may take several minutes."])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during the OCR process. Please check the file extension (pdf, jpg, jpeg, png allowed) before trying again. If the problem persists, please contact support."])}
        },
        "PROPERTY_TAX": {
          "PAYMENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
          "LEGAL_DEBTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Debtors"])},
          "PROPERTIES_AND_BUILDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Properties and Buildings"])},
          "PAYMENT_DEADLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Deadline"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Amount to Pay"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank ID"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creditor Name"])},
          "TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Amount"])},
          "IDENTIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier"])},
          "RIGHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right"])},
          "DESIGNATION_AND_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designation and Address"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining amount to repay"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax notice information"])},
          "ADDRESSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrant"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax number"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice reference"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role number"])},
          "TAX_DEPARTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax department"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner number"])},
          "TAX_COMMUNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax commune"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service ID"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of issue"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of assessment"])}
        },
        "INCOME_TAX": {
          "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the Tax Notice"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
          "FIP_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIP Number"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service ID"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Identification Number"])},
          "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Tax Income"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrant"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Number"])},
          "TAX_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Address"])},
          "PAYMENT_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
          "FAMILY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Details"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Amount to Pay"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Last Name"])},
          "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Parts"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Family Situation"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information on the tax assessment notice"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
          "MANDATE_UNIQUE_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique Mandate Reference"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remaining Amount to Repay"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creditor's Name"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Identifier"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice Reference"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner Number"])},
          "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Tax Year"])}
        },
        "BANK_DETAILS": {
          "BANK_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Code"])},
          "BRANCH_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branch Code"])},
          "ACCOUNT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number"])},
          "KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB Key"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
          "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Name"])},
          "BANK_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Address"])},
          "IBAN_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Validity"])},
          "ACCOUNT_NUMBER_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Number Validity"])},
          "DOCUMENT_ANALYZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzed Document"])},
          "DIRECT_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct Debit"])},
          "CHECKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checks"])}
        },
        "PAYSLIP": {
          "PAYSLIP_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Slip Information"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Information"])},
          "HIRE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hire Date"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "NET_PAYABLE_BEFORE_INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Payable Before Income Tax"])},
          "NET_PAYABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Payable After Income Tax"])},
          "PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Period"])}
        }
      }
    },
    "NO_PERSON_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No individuals found"])},
    "ERROR_MESSAGE_API": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An issue has been encountered"])}
  },
  "SCORE": {
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "WEB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "SECTOR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector"])},
    "COMPANY_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "DEFAULT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
    "REQUEST_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request ID"])},
    "HELPER_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We recommend conducting additional checks."])},
    "LOCAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local score  "])},
    "INTERNATIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International score "])}
  },
  "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Analysis of the Company"])},
  "TAX_REPORTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Return"])},
    "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiscal Period Duration"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closure Date"])},
    "TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turnover"])},
    "NET_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Income"])},
    "AUTO_FINANCE_CAPACITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Self-Financing Capacity"])},
    "NET_MARGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Margin"])},
    "FB_TB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Structure"])},
    "FINANCIAL_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Liquidity"])},
    "WORKING_CAPITAL_REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Capital Requirement"])},
    "WORKING_CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Capital"])},
    "FINANCIAL_SOLDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Balance"])},
    "DEBTS_SHORT_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short-Term Debt / Balance Sheet"])},
    "DEBTS_MIDDLE_LONG_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium-Long-Term Debt / Balance Sheet"])},
    "NOT_AVAILABLE_NEED_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax return not available. You can upload one to get its analysis."])}
  },
  "FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
  "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unavailable"])},
  "NO_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Available"])},
  "ENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity"])},
  "ENTITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entities"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month"])},
  "OF_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the month"])},
  "EFFECTIVE_RECIPIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficial Owner"])},
  "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
  "REPRESENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative"])},
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scores updated on"])},
  "VERIFICATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existence"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification of the existence of the information with the applicant"])}
  },
  "RELATED": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sufficient Link"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification of the association of the information with the applicant"])}
  },
  "FINISH": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close File"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you wish to retrieve the PDF summary before closing the page?"])}
  },
  "FUNCTION": {
    "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director"])}
  },
  "SOCIAL_NETWORKS": {
    "MAIL_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Linked"])},
    "PHONE_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Linked"])},
    "ADDRESS_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address Linked"])},
    "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked to Email"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked to Phone"])},
    "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Networks"])},
    "INFORMATIONS": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
    }
  },
  "TELEPHONE": {
    "LINETYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line Type"])},
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timezone"])},
    "CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Carrier"])},
    "ORIGINAL_CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original Carrier"])},
    "GEOLOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geolocation"])},
    "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])}
  },
  "HISTORIC_PAGE": {
    "HISTORIC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historical Records"])},
    "B2B_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Analysis"])},
    "B2C_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual Analysis"])},
    "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last 7 Days"])},
    "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "TABLE": {
    "PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per Page"])},
    "FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder"])},
    "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "MARKET_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Type"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journey ID"])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "NUMBER_OF_TAX_NOTICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Tax Notices"])},
    "NUMBER_OF_TAX_REPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Tax Reports"])},
    "NUMBER_OF_ID_CARDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of ID Cards"])},
    "NUMBER_OF_PASSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Passports"])},
    "NUMBER_OF_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Kbis"])},
    "NUMBER_OF_BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Bank Details"])},
    "NUMBER_OF_DRIVING_LICENSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Driving Licenses"])},
    "NUMBER_OF_RESIDENCE_PERMITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Residence Permits"])},
    "NUMBER_OF_PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of payslips"])},
    "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hour"])},
    "MAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
    "POSTAL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Address"])},
    "TELEPHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud Score"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Score"])},
    "TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "STATUS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder Status"])},
    "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
    "OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
    "SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthesis"])},
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "FILE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File number"])},
    "DATE_AND_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
    "DOCUMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document type"])},
    "DOCUMENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document name"])}
  },
  "REGEX": {
    "NOT_NULL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must not be empty."])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number is not valid."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is not valid."])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The format must be DD/MM/YYYY."])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must contain 9 digits."])},
    "NATIONAL_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must contain 10 digits."])},
    "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date too recent."])},
    "LETTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must contain letters."])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be in IBAN format."])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must consist of letters only"])},
    "UPPER_CASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should only contain uppercase letters, numbers, and underscores."])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link is not valid."])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should only contain numbers"])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can only contain letters, numbers and the symbols _+%-"])}
  },
  "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])},
  "FIRST_SEEN_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web usage traces"])},
  "NUMBER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Identification Number"])},
  "EMPLOYER_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employer verification"])},
  "PROCESS_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking Launch Process"])},
  "INFORMATION_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
  "SCORING_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Trust Score"])},
  "EMAIL_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Domain"])},
  "EMAIL_DOMAIN_CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Domain Creation Date"])},
  "EMAIL_DOMAIN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link with Email Domain"])},
  "EMAIL_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link with Email"])},
  "OTHER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership of Other Companies"])},
  "IS_RECENTLY_ACQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recently Acquired"])},
  "SCORING_ALTARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Scores"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "EMAIL_CORRELATION_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence between the email domain and the website."])},
  "CHECK_IBAN": {
    "CONTAINER": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Verification"])},
      "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])}
    },
    "RESULT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Analysis"])},
      "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifications"])},
      "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Connection"])},
      "typeTitulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Holder Type"])},
      "dateNaissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "autreNom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Name"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The verification request could not be completed due to a technical failure"])},
      "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Exists"])},
      "IBAN_NOT_EXISTS_ANYMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN does not exist anymore, and the account has been closed for less than 13 months"])},
      "IBAN_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN does not exist, or the account has been closed for more than 13 months"])},
      "IBAN_CAN_BE_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN cannot be verified: The user requested that their account not be checked with DIAMOND, or the account is in a foreign currency, or the account is not compatible with direct debits and transfers, and is therefore out of scope, ..."])},
      "HOLDER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Holder Type provided is correct"])},
      "HOLDER_TYPE_INCORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Holder Type provided is incorrect"])},
      "SIREN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided registration number matches the controlled IBAN"])},
      "SIREN_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided registration number does not match the controlled IBAN"])},
      "SIREN_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bank is unable to verify the registration number (e.g., because it does not know it)"])},
      "BIRTHDATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided birthdate matches the controlled IBAN"])},
      "BIRTHDATE_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided birthdate does not match the controlled IBAN"])},
      "BIRTHDATE_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bank is unable to verify the provided birthdate (e.g., because it does not know it)"])},
      "REASON_CODE": {
        "IncorrectAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid IBAN"])},
        "UnrecognisedInitiatingParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX of the Ordering Party"])},
        "InvalidIdentificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number"])},
        "MissingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "InvalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Data"])},
        "BICInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank not eligible for SEPAMAIL check"])}
      }
    },
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It seems that the queried bank is experiencing a connection issue with SepaMail. Thank you for your understanding."])}
  },
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "SYNTHESIS": {
    "FOLDER_STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder Status"])},
    "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyst"])},
    "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked"])},
    "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique Identifier"])},
    "PHONE_AND_SOCIAL_NETWORKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone and Social Networks"])},
    "LINKED_TO_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked to Email"])},
    "LINKED_TO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked to Phone"])},
    "BUSINESS_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification number"])},
    "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification"])}
  },
  "WARNING_LIST": {
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Name"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No alerts raised during our analysis"])},
    "MESSAGE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caution, the following parameter(s) was/were identified in a previous fraud case"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning List Meelo"])},
    "MESSAGE_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caution, the entered parameter was identified in a previous fraud case."])},
    "MESSAGE_NO_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No alerts raised during our analysis."])}
  },
  "SELECT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the analyses you want to launch"])},
  "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
  "PHONE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an indicator and enter a number without spaces or characters between the numbers."])},
  "BIRTHDATE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highly recommended to display the customer ecosystem."])},
  "EXAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
  "DOMAIN_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Information"])},
  "DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
  "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrant"])},
  "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar"])},
  "ADDITIONAL_CONTROLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Controls"])},
  "COMPANY_DOMICILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Domiciled"])},
  "EMAIL_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Domain Creation Date"])},
  "WEBSITE_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website Domain Creation Date"])},
  "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period of inactivity longer than 3 years"])},
  "LOCATION_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location at Risk"])},
  "OPERATOR_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator at Risk"])},
  "INITIAL_RISKY_OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial risky operator"])},
  "NO_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information, please launch the scores"])},
  "HAS_OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other company in collective proceedings"])},
  "HAS_OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other company removed"])},
  "HAS_OTHER_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other company"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "SUPPORT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Having an issue? Feel free to contact us"])},
  "SIDEBAR": {
    "KIT_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual Analysis"])},
    "KIT_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Analysis"])},
    "KIT_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking"])},
    "KIT_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Analysis"])},
    "HISTORICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Account"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "KIT_RECOVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery"])},
    "MEELO_KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Kit"])},
    "BACKOFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back office"])},
    "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])},
    "MEELO_ACADEMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Academy"])},
    "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document analysis"])},
    "USERS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My users"])},
      "PROFILE_AND_ACCESS_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile and access management"])},
      "USER_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User management"])},
      "GROUP_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group management"])},
      "LOCATION_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location management"])}
    },
    "LIST_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List management"])},
    "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "MONITORING": {
      "MY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My usage"])},
      "COMPANY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company usage"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption monitoring"])}
    },
    "RULES_ENGINE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules Engine"])},
      "COMPANY_RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Rules"])},
      "SETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rule Sets"])}
    },
    "COMPANY": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My company"])},
      "MANAGE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List management"])}
    }
  },
  "BREADCRUMBS": {
    "COMPANY_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
    "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
    "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decision"])},
    "JUSTIFICATORIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justificatories"])},
    "SCORE_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score choice"])},
    "RECEIVER_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receiver choice"])},
    "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "BATCH_RUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch run"])},
    "FILENAME_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing the file name"])}
  },
  "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])},
  "PRODUCT": {
    "VEHICLES_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total value of vehicles (including taxes)"])},
    "CONTRIBUTION_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contribution amount"])}
  },
  "CGI": {
    "DECISION": {
      "SUCCESS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final Step!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to document collection"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save and Continue"])}
      },
      "FAILED": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis Completed!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfavorable decision"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])}
      }
    },
    "DOCUMENTS": {
      "BLOCK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Download"])}
      },
      "ID_CARD": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity card"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front and back of your ID card"])}
      },
      "BANK_DETAILS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank statement"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank statement of your main account"])}
      },
      "KBIS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Registration Certificate"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Registration Certificate issued within the last 3 months"])}
      },
      "TAX_REPORT": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax return"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest tax return with at least 2 years of history"])}
      }
    },
    "CONGRATULATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations"])},
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have finished completing the file on the Meelo portal."])}
  },
  "TITLE_ACTION_DOCUMENT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition mode"])},
  "ONLY_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF only"])},
  "MULTI_REQUESTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-request"])},
    "DESCRIPTION_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the last 30 days, one or more cases have been created by"])},
    "DESCRIPTION_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with similar and/or identical information."])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No similar or identical cases entered in the last 30 days."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of records with the same email"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of records with the same phone"])},
    "REGISTRATIONNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of records with the same registration number"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of files with the same IBAN"])}
  },
  "RECOVERY": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
    "REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
    "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial analysis"])},
    "ANALYSIS_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis date"])},
    "BANK_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank information"])},
    "CUSTOMER_RELIABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reliability"])},
    "REJECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejection"])},
    "ON_AVERAGE_PER_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on average per month"])},
    "INTERVENTION_FEES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervention fees"])},
    "THIRD_PARTY_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Notice"])},
    "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gambling"])},
    "FINANCIAL_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Situation"])},
    "AVERAGE_DISPOSABLE_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average disposable income"])},
    "LOWEST_DISPOSABLE_INCOME_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest disposable income over the period"])},
    "LOWEST_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest income"])},
    "LACK_OF_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lack of income"])},
    "AVERAGE_MONTHLY_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average monthly income"])},
    "FINANCIAL_PRESSURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial pressure"])},
    "CREDITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credits"])},
    "DEBT_COLLECTION_AGENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debt collection agencies"])},
    "RISK_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk level"])},
    "LOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
    "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderate"])},
    "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risky"])},
    "LACK_OF_INCOME_SOURCES_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more sources of income have been detected"])},
    "LACK_OF_INCOME_SOURCES_NOT_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No income detected"])}
  },
  "BATCH": {
    "CLICK_ON_SCORES_BELOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click on one or multiple score(s) below to choose the scores to launch with batch."])},
    "MANUAL_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Manual"])},
    "MANUAL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For optimal use of the batch, we recommend downloading the template provided above. It is composed of the ideal structure suitable for all the scores you have previously selected. You can then fill the Excel file with your data and upload them a little further down on the page."])},
    "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch Cancellation Successful"])},
    "DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download batch result"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch Launch in Progress..."])},
    "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have received an email with the batch result"])},
    "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch Batch"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel Batch"])},
    "USAGE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage information"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "DOWNLOAD_TEMPLATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download template"])},
    "LIMIT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lines / file"])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desired output file name"])},
    "SCORE": {
      "REQUEST_ID": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity Fraud Score"])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Identity Fraud Score"])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Trust Score"])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy Sector Risk Evaluation Score"])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation Score"])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk Score"])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altares Score"])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellisphere Score"])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CreditSafe Score"])}
      },
      "DESCRIPTION": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Identity Fraud Score is a model that assesses the risk of identity fraud for a customer. It is based on the evaluation of several criteria: name, surname, email, telephone, but also the postal address or the date of birth."])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Professional Identity Fraud Score is a model that assesses the risk of identity fraud for a business client (in the context of a B2B relationship). It is based on the evaluation of several criteria: name, surname, email, telephone, but also the postal address or the date of birth."])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 'Score Confiance Entreprise' scoring service is a tool for assessing the company and the relationship between the applicant (individual) and the company (legal entity)."])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 'Energy Risk Score' is a model designed specifically for the energy sector that assesses the risk of customer defaults. It is based on the evaluation of several criteria: the telecommunications operator / the bank / IRIS data on the postal address / the monthly amount (exact or estimated)."])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The E-Reputation Score is a model designed to assess consumer satisfaction and trust towards the company based on the various reviews they have left."])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Risk Score is a model designed to assess an individual's creditworthiness based on their personal and payment information."])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Altares Score is a model designed to assess a company's solvency risk based on the results provided by the financial ratings company Altares."])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Ellisphere Score is a model designed to assess a company's solvency risk based on the results provided by the financial ratings company Ellisphere."])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The CreditSafe Score is a model designed to assess a company's solvency risk based on the results provided by the financial ratings company CreditSafe."])}
      }
    }
  },
  "FILE": {
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a file"])}
  },
  "IMPORTANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important"])},
  "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risky"])},
  "BACKOFFICE": {
    "GROUPS": {
      "COMPANY_GROUPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Management"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Group"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Group"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This group is used by users, are you sure you want to delete it?"])}
    },
    "PLACES": {
      "COMPANY_PLACES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place Management"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Place"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Place"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This place is used by users, are you sure you want to delete it?"])}
    },
    "USERS": {
      "SHORTCUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shortcuts"])},
      "COMPANY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company users"])},
      "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
      "PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
      "USER_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Modification"])},
      "PROFILE_ASSIGNMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Assignments"])},
      "USER_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User deletion"])},
      "DELETE": {
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to delete the user"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the company"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user will no longer be able to access their account."])},
        "ACTION_IRREVERSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action is irreversible."])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this user?"])}
      },
      "USER_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User profile"])},
      "TECHNICAL_ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Actions"])},
      "SUPERVISOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supervisor"])},
      "ACTIVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active account"])},
      "UPDATE_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The account status has been successfully modified."])},
      "USER_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding user"])},
      "RESET_PASSWORD": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetting a password"])},
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to send a password reset email for the user"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the email address"])},
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset email sent"])}
      },
      "RESET_API_KEY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetting an API key"])},
        "CONFIRM_RESET_USER": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to send an API key reset email for the user"])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the email address"])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important : The link to retrieve the API key is only valid for 30 minutes."])}
        },
        "CONFIRM_RESET_US": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to reset your API key."])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For security reasons, the value of your new key can only be retrieved once."])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This new key will be directly copied to your digital clipboard."])},
          "FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We advise you to stick it immediately in a secure place so that you can reuse it later."])}
        },
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API key reset email sent"])},
        "NEW_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your new API key has been copied to your clipboard"])}
      }
    },
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function"])},
    "ADVANCED_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced User"])},
    "ADVANCED_USER_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can create other advanced and/or basic users and access certain settings."])},
    "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
    "PROFILE_AND_ACCESS": {
      "COMPANY_PROFILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Profiles"])},
      "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
      "MESSAGE_PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a profile above to view the list of associated permissions."])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created by"])},
      "KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit"])},
      "MODULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
      "RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rights"])},
      "ADDING_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding a profile"])},
      "PROFILE_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile modification"])},
      "SAVE_PERMISSION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changes have been detected, don't forget to save them using the button above."])},
      "DESCRIPTION_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile description"])},
      "NO_PROFILE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No profile found"])}
    },
    "MONITORING": {
      "DOWNLOAD_BALANCE_SHEET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the balance sheet"])},
      "TOTAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total consumption"])},
      "MONTHLY_EVOLUTION_CURVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly evolution curve"])},
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
      "THIS_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This week"])},
      "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last week"])},
      "THIS_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This month"])},
      "LAST_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last month"])},
      "GENERAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General consumption"])},
      "COMPANY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption for the company"])},
      "DATA_ON_THE_WAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data is on the way..."])},
      "CONSUMPTION": {
        "OCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity Document Analysis"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentary Analysis"])},
        "CHECK_VALUE_COMPANY_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B IBAN Verification"])},
        "CHECK_VALUE_PERSONAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2C IBAN Verification"])},
        "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Analysis"])},
        "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML (Anti-Money Laundering) Analysis"])},
        "GET_LINKEDIN_PROFILE_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn Enrichment"])},
        "OPENBANKING_MEELO_OPENBANKING_PERSONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2C"])},
        "OPENBANKING_MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2B"])},
        "SCORING_ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellisphère Score"])},
        "SCORING_CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CreditSafe Score"])},
        "SCORING_WEB_SCORE_B2B_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Trust Score"])},
        "SCORING_WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual Identity Score"])},
        "SCORING_E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation Score"])},
        "SCORING_ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altare Score"])},
        "SCORING_SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Identity Score"])},
        "SELECTED_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption of selected users"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company consumption"])},
        "OF_MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consumption of my team"])},
        "MY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My consumption"])}
      },
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found. Run a first analysis to be able to consult your consumption."])}
    },
    "RULES_ENGINE": {
      "RULES": {
        "FOR_API_IMPLEMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For API implementation"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find the list of approved rules for your company."])},
        "RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "CONTAINED_IN_A_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contained in a set"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "GENERIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic"])},
        "SPECIFIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "MODIFY_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify rule"])},
        "RULE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rule name"])},
        "RULE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rule description"])},
        "VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
        "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default value"])},
        "PARAMETERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
        "ARGUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arguments"])},
        "EDIT_PARAMETER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit parameter"])},
        "PARAMETER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter name"])},
        "PARAMETER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter description"])},
        "FLOAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimal"])},
        "INTEGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integer"])},
        "STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
        "BOOLEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolean"])},
        "LIST_STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of texts"])},
        "RULE_PRESENT_IN_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rule present in the following sets"])},
        "RULE_V1_NOT_EDITABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This rule is a V1 rule and is not editable. If you wish to modify it, please contact Meelo."])}
      },
      "SETS": {
        "SETS_RULES_EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To view the rules of a set, please select the corresponding row in the left table area."])},
        "NO_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no rule sets for your company. Please contact Meelo to create one."])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "SETS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of sets"])},
        "SETS_RULES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of rules of the set"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find the list of created rule sets for your company."])},
        "ADD_OR_DELETE_RULE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For any changes regarding adding or deleting a rule in a set, please contact Meelo."])}
      }
    },
    "MY_COMPANY": {
      "USEFUL_CONTACTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful Contacts"])},
      "KITS_AND_MODULES_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kits and modules available"])},
      "ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
      "SUPPORT_MEELO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Meelo"])}
    }
  },
  "RESEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Research"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
  "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on"])},
  "RESULTS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results found"])},
  "NUMBER_OF_ITEMS_PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of items per page"])},
  "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
  "DATA_CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data contactability"])},
  "AND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "UNREACHABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unreachable"])},
  "LOCALE": {
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["German"])},
    "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanish"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dutch"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portuguese"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgium"])}
  },
  "ADVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reviews"])},
  "E_REPUTATION": {
    "AVERAGE_GRADE_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average grade not found"])},
    "LIST_TEN_LAST_REVIEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of the last 10 reviews"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo E-Reputation-Score"])},
    "NEGATIVE_REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negative review"])},
    "REVIEW_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review comment"])}
  },
  "NO_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No comment"])},
  "PRINT": {
    "FOLDER_INFO": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder Information"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
      "FOLDER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folder Status"])},
      "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyst"])},
      "UNIQ_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique Identifier"])}
    },
    "APPLICANT_OB": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant"])},
      "OB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking Analysis"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of Birth"])},
      "BIRTH_PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of Birth"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Phone"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Address"])},
      "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
      "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holder"])},
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Name"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main Account"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Salary"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average Monthly Rent"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratio of Expenses to Income"])},
      "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly Balance"])},
      "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Balance"])},
      "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking Supervision"])},
      "TOP_DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deferred Debit Detection"])},
      "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Transactions"])}
    },
    "DOCUMENT_ANALYSIS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Analysis"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compliance"])},
      "DOCUMENT_CONTROL": {
        "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Card"])},
        "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving License"])},
        "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
        "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence Permit"])},
        "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS - Company or Situation"])},
        "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS Situation Notice"])},
        "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS Extract"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticity - Analysis of non-digital alteration"])},
        "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Return"])},
        "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplified Tax Return"])},
        "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complex Tax Return"])},
        "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Notice - Income or Property"])},
        "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Income Tax"])},
        "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Tax"])},
        "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Details"])},
        "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Slip"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
      }
    },
    "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Details"])},
    "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Analysis"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Money Laundering and Terrorism Fighting"])},
    "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed OpenBanking Analysis"])},
    "ANALYSIS_B2B": {
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
      "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Information"])},
      "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Information"])},
      "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Name"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Number"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workforce"])},
      "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Date"])},
      "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Registration Number"])},
      "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Code"])},
      "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Form"])},
      "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capital"])},
      "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field of Activity"])},
      "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Closure Date"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domiciliation"])},
      "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrant"])},
      "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
      "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar"])},
      "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Known Shareholders and Beneficiaries"])},
      "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthdate"])},
      "NATIONALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationality"])},
      "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration number"])},
      "WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning List Meelo"])},
      "BUSINESS_REPRESENTS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Known Managers and Representatives"])},
      "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score Details"])},
      "COMPANY_SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Trust Score"])},
      "COMPANY_FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Finances"])},
      "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Documents"])},
      "ANNUAL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annual Accounts"])},
      "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Establishments"])},
      "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Evolution"])},
      "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Scores"])},
      "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation"])},
      "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailed OpenBanking Analysis"])},
      "ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Analysis"])},
      "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Money Laundering and Terrorism Fighting"])}
    },
    "COVER_PAGE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report on the conducted inspections"])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File created by"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File created on"])},
      "DOC_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Analysis"])},
      "DOC_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Analysis"])},
      "DOC_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open-Banking Analysis"])},
      "DOC_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Analysis"])},
      "DOC_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Synthesis"])}
    }
  },
  "ENRICHMENT": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To initiate a search for coordinates, please paste below the URL of your contact's LinkedIn profile."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Data enrichment"])},
    "NOPROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No LinkedIn profile found."])},
    "NOCORDINATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" No coordinates found."])},
    "TITLE_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordinates found :"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email(s):"])},
    "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional email(s) :"])},
    "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal email(s) :"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone(s):"])},
    "NO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No phone found"])},
    "NO_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No email found"])},
    "MATCH": {
      "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match with personal e-mail entered"])},
      "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match with professional e-mail entered"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match with phone entered"])}
    }
  },
  "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW"])},
  "DECLARATION": {
    "FRAUD": {
      "BUTTON_SIDEBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["declare a fraud"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud declaration"])},
      "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the form below for cases involving identity theft or confirmed fraud."])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Date of receipt of fraudulent request :"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Type of case"])},
      "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Declared email address"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Declared phone number"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated amount of fraud"])},
      "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case URL"])},
      "AVOID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was the fraud avoided ?"])},
      "TOOLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With which tool(s)"])},
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What elements lead you to believe it's fraud ?"])},
      "CONDITION_FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" * I accept the "])},
      "CONDITION_SECOND_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general terms of use "])},
      "CONDITION_THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo regarding manual fraud reporting"])},
      "PROFESIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional"])},
      "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])}
    }
  },
  "RECOMMENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional information, but highly recommended "])},
  "THANKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THANK YOU"])},
  "CONFIRM_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your declaration has been successfully sent"])},
  "MODULE": {
    "ALTARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altar Score"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "BUSINESS_OWNERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effective Business Owners"])},
    "CHECK_IBAN_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B IBAN Verification"])},
    "COMPANY_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Alerts"])},
    "COMPANY_ANNUAL_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Annual Accounts"])},
    "COMPANY_BODACC_ANNOUNCEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "COMPANY_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Establishments"])},
    "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Evolution"])},
    "COMPANY_FINANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Finances"])},
    "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])},
    "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Legal Documents"])},
    "COMPANY_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Trust Score"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit Safe Score"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation"])},
    "OB_ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking Alerts"])},
    "LITIGATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal situation - Litigation"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judged on "])},
      "DEMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demand : "])},
      "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location: "])},
      "MAIN_HEARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main hearing"])},
      "SUMMARY_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary procedure"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown"])}
    },
    "FINANCIAL_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial risk analysis section"])}
  },
  "CARTOGRAPHY": {
    "COMPANY_COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collective Procedure Company"])},
    "BUSINESS_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Name"])},
    "CARTOGRAPHY_OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartography of"])},
    "STUDIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company analyzed"])},
    "ACTIVE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active company"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["company Under collective procedure"])},
    "COMPANY_CARTOGRAPHY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company cartography"])},
    "DEREGISTERED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deregistered company"])},
    "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual"])},
    "COMPANY_ACTIVITIES_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company activity code"])},
    "COMPANY_ACTIVITIES_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company activity name"])},
    "RECENTLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recently"])},
    "UNVERIFIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverified company"])},
    "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlarge"])}
  },
  "MENU": {
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My account"])},
    "MY_CONSUPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My consumption"])},
    "PORTAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal Information"])},
    "LOG_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in as"])},
    "BACK_OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-office"])},
    "LOGOUT_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout from client account"])},
    "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "CONNECT_AS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in as "])},
    "PARAGRAPH": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to log in as"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So you will see the portal according to the permissions granted to this user."])},
      "FOURTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All your actions will be traced under the name of the client and yours."])},
      "FIFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please"])},
      "SIXTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["be cautious"])},
      "SEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["when using the portal as a client, and"])},
      "EIGHTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["respect the confidentiality"])},
      "NINTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of our clients' data."])},
      "TENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't forget to"])},
      "ELEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log out of the client account"])},
      "TWELFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["once your actions are completed."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of the company"])}
    },
    "TOAST": {
      "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are no longer logged in as"])},
      "LOG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now logged in as"])}
    },
    "BADGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logged in as"])},
    "MODAL": {
      "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
      "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])}
    }
  },
  "PORTAL_INFORMATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information on the MEELO portal"])},
    "SUBTITLE": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Meelo portal"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" : A recommendation at a glance"])}
    },
    "DESCRIPTION": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Meelo products at your fingertips to make your decision in just a few minutes."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our decision support portal allows you to use the Meelo products necessary for the proper verification of the customer who enters into contact with you."])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud Score, Identity Verification, Open Banking, Document Verification… Meelo products at your fingertips with minimal technical integration."])}
    },
    "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version of your portal"])},
    "ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environment"])},
    "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
    "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])},
    "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staging"])},
    "GDPR": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])},
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We attach great importance to the protection of your personal data. In accordance with the General Data Protection Regulation (GDPR), we only collect necessary information, obtain your explicit consent, use your and your customers' data legitimately, ensure its security, limit its retention and respect your rights."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have any questions or concerns regarding this privacy policy, you can consult our"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" or contact our DPO, at "])}
    },
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dedicated page"])}
  },
  "MAINTENANCE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following services may experience problems: "])},
    "FINISHED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember to refresh your portal for optimum use."])},
    "ONGOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing maintenance"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished maintenance"])},
    "SERVICE": {
      "AML_CFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti Money Laundering and Counter Financing of Terrorism Service"])},
      "CHECKOUT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic Signature Service"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Upload Service"])},
      "DOCUMENT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Control Service"])},
      "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External Documentation Service"])},
      "IAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication Service"])},
      "OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking Service"])},
      "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Portal"])},
      "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules Engine"])},
      "SCORING_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring Service"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Task Management Service"])},
      "TAX_NOTICE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Notice Analysis Service"])},
      "TAX_REPORT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax Return Analysis Service"])},
      "TRANSLATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Translation Service"])},
      "SHORTENER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal URL Shortening Service"])},
      "CHECK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Enrichment Service"])},
      "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Document Upload Service"])},
      "TRACKETIO_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Tracking Element Analysis Service"])},
      "STORAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Document Storage Service"])},
      "ALERTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerting Service"])},
      "TRACKETIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Tracking Service on Browser"])},
      "OPEN_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Revenue Service"])},
      "ENRICHMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Enrichment Service"])},
      "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image Search and Matching Service"])}
    }
  },
  "FOLDER": {
    "WORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["folder"])},
    "STATE": {
      "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])},
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted"])},
      "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refused"])},
      "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
      "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])}
    },
    "DELETION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deletion of the folder"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to delete the"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and the data that constitutes it"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this folder?"])}
      }
    },
    "EDITION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update of the file status"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will no longer be able to continue entering data in this file"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to change the file status?"])}
      },
      "STATE": {
        "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in progress"])},
        "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["initiate"])},
        "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["refuse"])},
        "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wait"])},
        "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
        "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])}
      }
    },
    "UPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of the file updated"])}
  },
  "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No."])},
  "NOTIFICATIONS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifications"])},
    "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New "])},
    "READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read "])},
    "MARK_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MARK ALL AS READ"])},
    "DELETE_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DELETE ALL"])},
    "DATE": {
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TODAY "])},
      "YESTERDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YESTERDAY"])},
      "OLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OLDER"])}
    },
    "TIMESTAMP": {
      "NOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just Now"])},
      "PREFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "SUFFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ago"])},
      "MINUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute"])},
      "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes"])},
      "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
      "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])}
    },
    "ACTIONS": {
      "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "SERVICES": {
      "OPEN_BANKING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking Analysis - Analysis failure"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking - Failed analysis"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The OpenBanking analysis of the file"])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has been completed."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has been completed, click here to view the results."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred during the OpenBanking analysis of the file, click here to view the results."])}
      },
      "LIVE_CHECK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doubt Clearance - Analysis completed"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doubt Clearance - Analysis failed"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doubt clearance of the file "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is completed."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is completed. Click on the notification to view the result."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem with the doubt clearance. Click on the notification to view the file."])}
      },
      "BATCH_CHECK_COMPANY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Analysis - Batch completed"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Analysis - Batch failed"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company analysis batch "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is completed."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is completed."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company analysis batch encountered a problem."])}
      },
      "BATCH_CHECK_IBAN": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Analysis - Batch completed"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN Analysis - Batch failed"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The IBAN analysis batch "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is completed."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is completed."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The IBAN analysis batch encountered a problem."])}
      },
      "BATCH_SCORING_PROXY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Batch completed"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Batch failed"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The scoring analysis batch "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is completed."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is completed."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The scoring analysis batch encountered a problem."])}
      }
    }
  },
  "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
  "OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "THE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the"])},
  "SCORE_CREDIT_SAFE": {
    "RISK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial risk"])},
    "PAYMENT_TREND_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment trend"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found."])},
    "OTHER_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other additional information"])},
    "PRESENCEOFJUDGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of judgment"])},
    "PRESENCEOFPRIVILEGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of privilege"])},
    "PRESENCEOFSANCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presence of the DGCCRF sanction"])},
    "HEADBAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To learn more about the financial health of this company, please activate your CreditSafe subscription"])},
    "RISK": {
      "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low financial risk"])},
      "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderate financial risk"])},
      "HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High financial risk"])},
      "NOT_SCORED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not verifiable"])}
    },
    "PAYMENT_TREND": {
      "WORSENING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deteriorating payment trend"])},
      "IMPROVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment trend improving"])},
      "STABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stable payment trend"])},
      "NOT_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment trend not available"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverifiable payment trend"])}
    }
  },
  "MALICIOUS_PAYLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid input data"])},
  "RESET_API_KEY": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your API key"])},
    "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can view your new API key only once"])},
    "CANNOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can no longer view your API key, please contact your reference person or reach out to Meelo support"])},
    "COPY_YOUR_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy your key"])}
  },
  "DQE_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone line matching"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred, the search could not be completed."])},
    "NO_SEARCH_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search is not available for this phone operator."])},
    "MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching"])},
    "KEY": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birth Date"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Address"])}
    },
    "LAUNCH_MATCHING_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch the matching search"])},
    "NO_GLOBAL_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The check has been done but cannot be completed."])}
  }
}